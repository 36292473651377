/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * AccountMarketValue
 * This model validates the request body for the account market value
 * endpoint. This model is used to update the account's market value.
 */
export interface AccountMarketValue {
  /**
   * Market Value
   * Market value of account
   * @default "0"
   */
  market_value?: string;
  /** Type of asset */
  account_type: OrderAssetTypeEnum;
  /**
   * Description
   * Description of account. This could be used by the frontend to display the account name.
   */
  description: string;
}

/**
 * AccountResponse
 * Account response model that will be returned to the frontend to display bank accounts of a user.
 */
export interface AccountResponseInput {
  /** Account Id */
  account_id: string;
  /** Display Account Number */
  display_account_number: string;
  /** Account Type */
  account_type: string;
  /** Available Balance */
  available_balance: number | string;
  /** Account Description */
  account_description: string;
  /** Deposits Allowed */
  deposits_allowed?: boolean | null;
  /** Withdrawals Allowed */
  withdrawals_allowed?: boolean | null;
}

/**
 * AccountResponse
 * Account response model that will be returned to the frontend to display bank accounts of a user.
 */
export interface AccountResponseOutput {
  /** Account Id */
  account_id: string;
  /** Display Account Number */
  display_account_number: string;
  /** Account Type */
  account_type: string;
  /** Available Balance */
  available_balance: string;
  /** Account Description */
  account_description: string;
  /** Deposits Allowed */
  deposits_allowed?: boolean | null;
  /** Withdrawals Allowed */
  withdrawals_allowed?: boolean | null;
}

/**
 * AddDefaultEducationContentSectionRequest
 * Education content topic add model.
 */
export interface AddDefaultEducationContentSectionRequest {
  /**
   * Uuid
   * UUID of the topic.
   */
  uuid: string;
  /**
   * Title
   * Title of the topic.
   */
  title: string;
  /**
   * Image
   * Image URL of the topic.
   */
  image: string | null;
}

/**
 * AddDefaultEducationContentTopicRequest
 * Education content topic add model.
 */
export interface AddDefaultEducationContentTopicRequest {
  /**
   * Uuid
   * UUID of the topic.
   */
  uuid: string;
  /**
   * Section Uuid
   * UUID of the section the topic belongs to.
   */
  section_uuid: string;
  /**
   * Title
   * Title of the topic.
   */
  title: string;
  /**
   * Image
   * Image URL of the topic. Can be omitted or set to None if no image.
   */
  image?: string | null;
  /**
   * Body
   * Body of the topic.
   */
  body: string;
}

/**
 * AdminUser
 * Information regarding admin users which are persisted exclusively in
 * AWS Cognito
 */
export interface AdminUser {
  /**
   * Pool Id
   * Cognito Pool ID
   */
  pool_id: string;
  /**
   * Username
   * User's unique username in pool
   */
  username: string;
  /**
   * Sub
   * User's Cognito ID in pool
   */
  sub: string;
  /**
   * Fi Id
   * Financial Institution user belongs to
   */
  fi_id: string;
  /**
   * Name
   * User's Name
   */
  name: string | null;
  /**
   * Given Name
   * User's First Name
   */
  given_name: string | null;
  /**
   * Family Name
   * User's Last Name
   */
  family_name: string | null;
  /**
   * Email
   * User's Email Address
   */
  email: string | null;
  /**
   * Email Verified
   * Email Address has been Verified
   */
  email_verified: boolean | null;
  /**
   * Phone Number
   * User's Phone Number
   */
  phone_number: string | null;
  /**
   * Phone Number Verified
   * Phone Number has been Verified
   */
  phone_number_verified: boolean | null;
  /** User's Ops Center Role Type */
  user_type: OpsCenterRoleType | null;
  /**
   * Enabled
   * Flag if user is enabled and can login
   */
  enabled: boolean | null;
  /**
   * Status
   * User's Cognito Status Flag
   */
  status: string | null;
  /**
   * Date Created
   * Date the user was created
   */
  date_created: string | null;
  /**
   * Date Updated
   * Date the user record last updated
   */
  date_updated: string | null;
}

/**
 * AdminUserUpdateRequestBody
 * Request body to pass to create or update an Admin user
 * Any fields that default to None will not be updated or cleared in the
 * user's Cognito profile if they are left unset or None
 */
export interface AdminUserUpdateRequestBody {
  /**
   * Given Name
   * User's First Name
   * @pattern ^[^<>]+$
   */
  given_name: string;
  /**
   * Family Name
   * User's Last Name
   * @pattern ^[^<>]+$
   */
  family_name: string;
  /**
   * Email
   * User's Email Address, leave unset to not have field be changed
   */
  email?: string | null;
  /**
   * Phone Number
   * User's Phone Number, leave unset to not have field be changed
   */
  phone_number?: string | null;
  /** User's Ops Center Role Type */
  user_type: OpsCenterRoleType;
}

/** AlkamiRequestBody */
export interface AlkamiRequestBody {
  /**
   * Fi Id
   * The FI ID
   */
  fi_id: string;
  /**
   * User Account Id
   * The User Account ID
   */
  user_account_id: string;
}

/** AlkamiResponseBody */
export interface AlkamiResponseBody {
  /**
   * Redirect Url
   * The redirect URL to InvestiFI's App
   */
  redirect_url: string;
}

/** AssetBalance */
export interface AssetBalance {
  /**
   * Total Buy Orders
   *
   *         Total number of buy orders for this token
   *
   * @default 0
   */
  total_buy_orders?: number;
  /**
   * Amount Available
   *
   *         Amount of this token user can sell
   *
   * @default 0
   */
  amount_available?: string;
  /**
   * Total Sell Orders
   *
   *         Total number of sell orders for this token
   *
   * @default 0
   */
  total_sell_orders?: number;
  /**
   * Total Orders
   *
   *         Total number of all orders for this token
   *
   * @default 0
   */
  total_orders?: number;
  /**
   * Amount Owned
   *
   *         Amount of this token user owns
   *
   * @default 0
   */
  amount_owned?: string;
  /**
   * Total Cost
   *
   *         total_cost represents the cumlative value of all transactions for a specific token.
   *         In the case of buy orders, it increases by the tx_gross at the time of each purchase.
   *         For sells, it decreases based on the average cost of the owned tokens multiplied by the quantity sold.
   *
   */
  total_cost?: string | null;
  /**
   * Avg Cost
   *
   *         avg_cost denotes the average cost per unit of a specific token, calculated over all buy transactions.
   *         It is determined by dividing the total cost by the total amount of the token owned.
   *         This metric is updated only when new buy orders are executed, reflecting the average price paid per token
   *         unit over time. It remains unchanged during sell orders, providing a consistent reference for calclating
   *         the 'cost basis' (total cost) of the token holdings.
   *
   */
  avg_cost?: string | null;
}

/**
 * Balance
 * Returns balances for all cryptocurrency and securities the user has a
 * balance or transaction history for.
 */
export interface Balance {
  /** Tokens */
  tokens?: Record<string, AssetBalance>;
  /** Securities */
  securities?: Record<string, AssetBalance>;
  /**
   * Date Last Order
   *
   *         Datetime of last order placed by user
   *
   */
  date_last_order?: string | null;
  /**
   * Date Updated
   *
   *         Datetime balances were last updated for user
   *
   * @format date-time
   */
  date_updated?: string;
  /**
   * Last Order Id
   *
   *         Last order placed by user
   *
   */
  last_order_id?: string | null;
  /**
   * Total Error Orders
   *
   *         Cumulative total of crypto orders that had an error such as failed placed by user for all tokens
   *
   * @default 0
   */
  total_error_orders?: number;
  /**
   * Total Orders
   * Returns the total number of buy and sell cryptocurrency and security
   * orders for this user's balance.
   */
  total_orders: number;
  /**
   * Total Buy Orders
   * Returns the total number of buy cryptocurrency and security orders for
   * this user's balance.
   */
  total_buy_orders: number;
  /**
   * Total Sell Orders
   * Returns the total number of sell cryptocurrency and security orders for
   * this user's balance.
   */
  total_sell_orders: number;
}

/**
 * BalanceSearchModel
 * Balance information for a user, including securities and tokens.
 */
export interface BalanceSearchModel {
  /**
   * Tokens
   * List of tokens owned by the user.
   */
  tokens?: UserTokenHoldingsSearchModel[] | null;
  /**
   * Securities
   * List of securities owned by the user.
   */
  securities?: UserSecuritiesHoldingsSearchModel[] | null;
  /**
   * Date Last Order
   *
   *         Datetime of last order placed by user
   *
   */
  date_last_order?: string | null;
  /**
   * Date Updated
   *
   *         Datetime balances were last updated for user
   *
   * @format date-time
   */
  date_updated?: string;
  /**
   * Last Order Id
   *
   *         Last order placed by user
   *
   */
  last_order_id?: string | null;
  /**
   * Total Error Orders
   *
   *         Cumulative total of crypto orders that had an error such as failed placed by user for all tokens
   *
   * @default 0
   */
  total_error_orders?: number;
  /**
   * Present Value
   * Present value of the user's holdings.
   * @default 0
   */
  present_value?: number;
  /**
   * Crypto Market Value
   * Market value of the user's cryptocurrency holdings.
   * @default 0
   */
  crypto_market_value?: string;
  /**
   * Securities Market Value
   * Market value of the user's securities holdings.
   * @default 0
   */
  securities_market_value?: string;
  /** Total Orders */
  total_orders: number;
  /** Total Buy Orders */
  total_buy_orders: number;
  /** Total Sell Orders */
  total_sell_orders: number;
  /** Owns Crypto */
  owns_crypto: boolean;
  /** Owns Securities */
  owns_securities: boolean;
}

/**
 * BankingAccountTypeEnum
 * List of supported Banking Account Types. This is a generic list of account types
 * TODO: Migrate the other account types to this list
 */
export enum BankingAccountTypeEnum {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

/** BankingCoreClientCuAnswersAccountTypesEnum */
export enum BankingCoreClientCuAnswersAccountTypesEnum {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

/**
 * BankingCoreClientEnum
 * List of supported Banking Core Clients
 * These are essentially APIs that interact with several banking cores
 */
export enum BankingCoreClientEnum {
  Alkami = 'Alkami',
  Banno = 'Banno',
  CuAnswers = 'CuAnswers',
  Dummy = 'Dummy',
  Jxchange = 'Jxchange',
  Ncr = 'Ncr',
  NcrSAML = 'NcrSAML',
  Q2 = 'Q2',
  SymXchange = 'SymXchange',
}

/** BankingCoreClientNcrAccountTypesEnum */
export enum BankingCoreClientNcrAccountTypesEnum {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

/** BankingCoreClientQ2AccountTypesEnum */
export enum BankingCoreClientQ2AccountTypesEnum {
  Q2BASICCHECKING = 'Q2 BASIC CHECKING',
  Q2VALUECHECKING = 'Q2 VALUE CHECKING',
  Q2PREMIERCHECKING = 'Q2 PREMIER CHECKING',
  Q2ULTIMATECHECKING = 'Q2 ULTIMATE CHECKING',
  Q2VALUESAVINGS = 'Q2 VALUE SAVINGS',
  Q2VALUESAVINGS1 = 'Q2 VALUE SAVINGS',
  Q2PREMIERSAVINGS = 'Q2 PREMIER SAVINGS',
  Q2ULTIMATESAVINGS = 'Q2 ULTIMATE SAVINGS',
  TOTALACCESSSAVINGS = 'TOTAL ACCESS SAVINGS',
  TOTALACCESSRETAILCHECKING = 'TOTAL ACCESS RETAIL CHECKING',
}

/** BankingCoreClientSymXchangeAccountTypesEnum */
export enum BankingCoreClientSymXchangeAccountTypesEnum {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

/**
 * BankingCoreEnum
 * List of supported Banking Cores
 */
export enum BankingCoreEnum {
  CuAnswers = 'CuAnswers',
  Symitar = 'Symitar',
  Dummy = 'Dummy',
}

/**
 * BankingUserMemberTypeEnum
 * List of supported Banking User Member Types
 */
export enum BankingUserMemberTypeEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
  UNKNOWN = 'UNKNOWN',
}

/** BaseTermsAndConditionsUrls */
export interface BaseTermsAndConditionsUrls {
  /** The URL information for the terms and conditions document */
  terms_and_conditions?: UrlLink | null;
  /** The URL information for the privacy policy document */
  privacy_policy?: UrlLink | null;
  [key: string]: any;
}

/** Body_fi_onboarding_v2_onboarding_fi_post */
export interface BodyFiOnboardingV2OnboardingFiPost {
  /** todo: review the ops center required fields and remove anything unused */
  financial_institution_record: FinancialInstitutionInput;
  financial_institution_custodian_record: FinancialInstitutionCustodian;
  financial_institution_liquidity_provider_record: FinancialInstitutionLiquidityProvider;
  financial_institution_core_client_record?: FinancialInstitutionCoreClient | null;
  financial_institution_core_client_secrets?: FinancialInstitutionCoreClientNcrSecrets | null;
}

/** Body_update_financial_institution_documents_v2_cfi_admin_rest_financial_institution__fi_id__documents__document_type__put */
export interface BodyUpdateFinancialInstitutionDocumentsV2CfiAdminRestFinancialInstitutionFiIdDocumentsDocumentTypePut {
  /**
   * File
   * PDF file only
   * @format binary
   */
  file: File;
  /**
   * Label
   * Label for the document link
   */
  label: string;
}

/** Body_upload_terms_document_v2_cfi_admin_rest_terms__terms_provider__documents__document_type__put */
export interface BodyUploadTermsDocumentV2CfiAdminRestTermsTermsProviderDocumentsDocumentTypePut {
  /**
   * File
   * PDF document to be uploaded - must be in PDF format
   * @format binary
   */
  file: File;
  /**
   * Label
   * Display label for the document link shown to users
   */
  label: string;
}

/**
 * BuySideSecuritiesTradingPeriod
 * Securities Buy Side TradingPeriod sub-model. This Pydantic model
 * is used to represent the buy side of the Securities TradingPeriod and
 * is nested within the SecuritiesTradingPeriod model, which itself
 * is nested within the TradingPeriod model.
 */
export interface BuySideSecuritiesTradingPeriod {
  /** @default "open" */
  status?: LibModelReconciliationBuySideSecuritiesTradingPeriodStateMachineStates;
  /**
   * Total Transaction Volume
   * @default "0"
   */
  total_transaction_volume?: string;
  /**
   * Total Transaction Fees
   * @default "0"
   */
  total_transaction_fees?: string;
}

/**
 * CalculateMonthlyFeesRequest
 * FastAPI request model for calculate-monthly-fees endpoint.
 */
export interface CalculateMonthlyFeesRequest {
  /** Static enum of calendar months. */
  month: MonthEnum;
  /** Year */
  year: number;
}

/**
 * CalculateMonthlyFeesResponse
 * FastAPI response model for calculate-monthly-fees endpoint.
 */
export interface CalculateMonthlyFeesResponse {
  /** Total Fees */
  total_fees: string;
  /** Total Volume */
  total_volume: string;
  /** First Trading Period */
  first_trading_period: string | null;
  /** Last Trading Period */
  last_trading_period: string | null;
  /** Fraud Fees */
  fraud_fees: string;
  /** Total Fees To Invoice */
  total_fees_to_invoice: string;
}

/**
 * ComplianceSuitabilityFields
 * When a user submits their kyc fields for kyc approval, these are the fields that we save
 * to our database internally to make a preliminary decision as to if they are suitable for trading securities.
 * Once they pass this stage, we submit these fields to the securities provider for final approval.
 */
export interface ComplianceSuitabilityFields {
  /**
   * Employer
   * User's Employer
   */
  employer?: string | null;
  /**
   * Income Per Year Integer
   * User's Annual Income
   */
  income_per_year_integer?: number | null;
  /**
   * Net Worth Integer
   * User's Net Worth
   */
  net_worth_integer?: number | null;
  /** User's Investing Experience Level */
  level_stocks?: InvestmentExperienceLevelEnum | null;
  /**
   * Executive Or Shareholder
   * Are you or a family member an executive or 10% shareholder of a publicly traded company?
   */
  executive_or_shareholder?: string | null;
  /**
   * Work For Exchange Or Brokerage
   * Do you or a family member work for a stock exchange or brokerage?
   */
  work_for_exchange_or_brokerage?: 'Yes' | 'No' | null;
  /**
   * Subject To Backup Withholding
   * Are you subject to backup withholding?
   */
  subject_to_backup_withholding?: 'Yes' | 'No' | null;
  /** What kind of Investor are you? (Risk tolerance) */
  risk_tolerance?: RiskToleranceEnum | null;
  /** User's Investment Objective */
  investment_objective?: InvestmentObjectiveEnum | null;
}

/**
 * CryptoBuyOrderRequestBody
 * Represents the request body used in placing BUY orders via API for cryptocurrencies.
 */
export interface CryptoBuyOrderRequestBody {
  /**
   * Order Side
   * Trade side of the transaction such as BUY or SELL
   * @default "BUY"
   */
  order_side?: 'BUY';
  /**
   * Order Price
   *
   *         Total price in FIAT the user has apportioned to be placed in the purchase.
   *         The intent is for this amount to be fully used in purchasing the maximum
   *         amount of the token that can be had at this price.
   */
  order_price: number | string;
  /**
   * Net Order Quantity
   * The net asset quantity quoted on the FE for BUY orders.
   */
  net_order_quantity?: string | null;
  /**
   * List of order asset types
   * @default "CRYPTO"
   */
  asset_type?: OrderAssetTypeEnum;
  /**
   * Currency
   * Currency used in transaction to either purchase the asset or receive as payment in sale.
   * @default "USD"
   */
  currency?: 'USD';
  /**
   * Account Number
   *
   *     The FI specific ID of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_number?: string | null;
  /**
   * Account Type
   *
   *     The FI specific Name of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_type?: string | null;
  /** Market symbol of coin exchanged in this transaction. */
  token: TokenEnum;
}

/**
 * CryptoSellOrderRequestBody
 * Represents the request body used in placing SELL orders via API for cryptocurrencies.
 */
export interface CryptoSellOrderRequestBody {
  /**
   * Order Side
   * Trade side of the transaction such as BUY or SELL
   * @default "SELL"
   */
  order_side?: 'SELL';
  /**
   * Order Quantity
   * Amount of asset to sell.
   */
  order_quantity: number | string;
  /**
   * Net Order Price
   * The net fiat quantity quoted on the FE for SELL orders.
   */
  net_order_price?: string | null;
  /**
   * List of order asset types
   * @default "CRYPTO"
   */
  asset_type?: OrderAssetTypeEnum;
  /**
   * Currency
   * Currency used in transaction to either purchase the asset or receive as payment in sale.
   * @default "USD"
   */
  currency?: 'USD';
  /**
   * Account Number
   *
   *     The FI specific ID of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_number?: string | null;
  /**
   * Account Type
   *
   *     The FI specific Name of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_type?: string | null;
  /** Market symbol of coin exchanged in this transaction. */
  token: TokenEnum;
}

/**
 * CryptoTradingPeriod
 * Crypto TradingPeriods nested within a TradingPeriod.
 */
export interface CryptoTradingPeriod {
  /**
   * All possible states for a TradingPeriod.
   * @default "open"
   */
  status?: LibModelReconciliationCryptoTradingPeriodStateMachineStates;
  /**
   * Total Transaction Volume
   * @default "0"
   */
  total_transaction_volume?: string;
  /**
   * Total Transaction Fees
   * @default "0"
   */
  total_transaction_fees?: string;
}

/** CuAnswersRequestBody */
export interface CuAnswersRequestBody {
  /**
   * Access Token
   * The Access Token from CU*Answers
   * @pattern ^[a-zA-Z0-9À-ÿ_#':/\-.,+ &]+$
   */
  access_token: string;
}

/**
 * CurrencyEnum
 * List of supported Fiat currencies in ISO 4217
 */
export enum CurrencyEnum {
  USD = 'USD',
  USDC = 'USDC',
}

/**
 * CustodianEnum
 * List of supported Custodians
 */
export enum CustodianEnum {
  DummyCustodian = 'DummyCustodian',
  SFOX = 'sFOX',
}

/**
 * DataStatusEnum
 * Status associated with an operation in getting data from a system
 * such as the CryptoWatch provider
 * When data is retrieved successfully will be OK, but if there were issues
 * in getting the data will be set to ERROR
 */
export enum DataStatusEnum {
  OK = 'OK',
  ERROR = 'ERROR',
}

/**
 * DbpAuthRequest
 * The request from the dbp-frontend when a user logs in.
 */
export interface DbpAuthRequest {
  /**
   * Code
   * The code that the dbp-frontend received from the Identity Provider. This will be exchanged for a token.
   * @pattern ^[a-zA-Z0-9À-ÿ_#':/\-.+~]+$
   */
  code: string;
  /**
   * Code Verifier
   * The code verifier that the dbp-frontend used to get the code from the Identity Provider. This is used during PKCE.
   */
  code_verifier?: string | null;
}

/**
 * DbpAuthResponse
 * The response from the dbp-frontend when a user logs in.
 */
export interface DbpAuthResponse {
  /**
   * The type of authentication that should be used in the dbp-frontend.
   * @default "OIDC"
   */
  auth_type?: IdentityProviderAuthenticationTypeEnum | null;
  /**
   * Redirect Url
   * The url that the user should be redirected to after logging in. This will most likely be back to where the dbp-frontend is hosted for that FI.
   */
  redirect_url?: string | null;
}

/**
 * DbpTokenResponse
 * The response to the dbp-frontend when a user logs in. This will include the id token and access token.
 */
export interface DbpTokenResponse {
  /**
   * Id Token
   * The id token that the dbp-frontend should use to authenticate the user.
   */
  id_token?: string;
  /**
   * Expires In
   * The number of seconds until the id token expires.
   */
  expires_in?: string;
}

/**
 * DefaultEducationContent
 * Education content model.
 */
export interface DefaultEducationContent {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /** The product the education section is for. */
  education_type: EducationTypeEnum;
  /**
   * Sections
   * Default education content.
   */
  sections: DefaultEducationContentSection[];
  /**
   * Content
   * Default education content.
   * @default []
   */
  content?: DefaultEducationContentSection[];
}

/**
 * DefaultEducationContentSection
 * Education content section model.
 */
export interface DefaultEducationContentSection {
  /**
   * Uuid
   * UUID of the section.
   */
  uuid?: string;
  /**
   * Title
   * Title of the section.
   */
  title: string;
  /**
   * Image
   * Image URL of the section.
   * @default ""
   */
  image?: string | null;
  /**
   * Topics
   * Topics of the section.
   */
  topics: DefaultEducationContentTopic[];
}

/**
 * DefaultEducationContentTopic
 * Education content topic model.
 */
export interface DefaultEducationContentTopic {
  /**
   * Uuid
   * UUID of the topic.
   */
  uuid?: string;
  /**
   * Title
   * Title of the topic.
   */
  title: string;
  /**
   * Image
   * Image URL of the topic.
   * @default ""
   */
  image?: string | null;
  /**
   * Body
   * Body of the topic.
   */
  body: string;
}

/**
 * DeleteDefaultEducationContentSectionRequest
 * Delete education content section request model.
 */
export interface DeleteDefaultEducationContentSectionRequest {
  /**
   * Section Uuid
   * UUID of the section.
   */
  section_uuid: string;
}

/**
 * DeleteDefaultEducationContentTopicRequest
 * Delete education content topic request model.
 */
export interface DeleteDefaultEducationContentTopicRequest {
  /**
   * Topic Uuid
   * UUID of the topic.
   */
  topic_uuid: string;
  /**
   * Section Uuid
   * UUID of the section.
   */
  section_uuid: string;
}

/**
 * Dividend
 * Data model for `Dividends`. These are typically cash payouts issued by a security
 * and paid to the user's account. Dividends can also be issued as stock, in which case
 * the `dividend_security_quantity` field will be populated with the quantity of stock
 * issued as a dividend.
 *
 * NOTE: This model uses a looser p_key schema, which allows us to query this table
 * more flexibly. Examples:
 *
 * ```
 * # Return all dividends
 * pkey = Dividend.partition_key_spec()
 * Dividend.query(p_key=pkey)
 *
 * # Return all dividends for a specific FI
 * Dividend.query(p_key=pkey, filter_condition=A.fi_id==fi_id)
 *
 * # Return all dividends for a specific user
 * Dividend.query(p_key=pkey, filter_condition=A.user_account_id==user_id)
 *
 * # Return all dividends for a specific trading period
 * Dividend.query(p_key=pkey, filter_condition=A.trading_period_id==tp.composite_key())
 *
 * # Return all dividends for a specific security
 * Dividend.query(p_key=pkey, filter_condition=A.securities_identifier==security.ticker)
 * ```
 */
export interface Dividend {
  /**
   * Xfr Err
   *
   * Determines if there was an error during the transfer on the banking core.
   * 0 for successful transfers. -1 for failed transfers.
   */
  xfr_err?: string | null;
  /**
   * Xfr Err Code
   * Error code thrown from the banking core for failed transfers.
   */
  xfr_err_code?: string | null;
  /**
   * Xfr Err Desc
   * Error description from the banking core for failed transfers.
   */
  xfr_err_desc?: string | null;
  /**
   * Xfr Id
   *
   * Primary key created in the online banking environment to identify the transfer
   * from the user's account to the FI's account and vice versa for sales.
   */
  xfr_id?: string | null;
  /**
   * Xfr Time
   * The time that the transfer was made in the banking core, in EPOCH time.
   */
  xfr_time?: string | null;
  /**
   * Xfr Amt
   * The total amount that was transferred in banking core.
   */
  xfr_amt?: string | null;
  /**
   * Xfr Rollback Time
   * The time that a rollback transaction was made from the GL
   * to the members account during an unsuccessful BUY order.
   */
  xfr_rollback_time?: string | null;
  /**
   * Xfr Rollback Id
   * The id of a rollback transaction from the GL to the
   * member's account during an unsuccessful BUY order.
   */
  xfr_rollback_id?: string | null;
  /**
   * Xfr Rollback Amount
   * The amount of a rollback transaction from the GL to the
   * member's account during an unsuccessful BUY order.
   */
  xfr_rollback_amount?: string | null;
  /**
   * Xfr Rollback Err Desc
   *
   * Determines if there was an error during the rollback transfer on the banking core.
   * 0 for successful transfers. -1 for failed transfers.
   */
  xfr_rollback_err_desc?: string | null;
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account Id
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  /**
   * Transact Time
   * UTC time of that the dividend was created.
   * @format date-time
   */
  transact_time?: string;
  /**
   * Trading Period Id
   * The trading period ID for the dividend.
   */
  trading_period_id: string;
  /**
   * Securities Ticker
   * Ticker symbol of the security that issued the dividend.
   */
  securities_ticker: string;
  /**
   * Securities Identifier
   * Unique identifier of the security in the LP's system.
   */
  securities_identifier: string;
  /**
   * Liquidity Provider Dividend Id
   * A unique ID for the dividend within the liquidity provider's system.
   */
  liquidity_provider_dividend_id: string;
  /**
   * Dividend Security Quantity
   * Optional quantity of stock issued as a dividend.
   */
  dividend_security_quantity?: string | null;
  /**
   * Dividend Notional Amount
   * Optional amount of cash issued as a dividend.
   */
  dividend_notional_amount?: string | null;
  /**
   * Status of the dividend.
   * @default "PENDING_SETTLEMENT"
   */
  status?: DividendStatusEnum;
}

/**
 * DividendStatusEnum
 * Dividend statuses (CORE-953)
 *
 * Pending Settlement: The dividend (payout) has been issued
 * by the stock, available in RQD, and needs to be settled into
 * the user's DDA account with the FI.
 *
 * OK: The dividend has been successfully settled into the user's
 * DDA account with the FI.
 */
export enum DividendStatusEnum {
  PENDING_SETTLEMENT = 'PENDING_SETTLEMENT',
  OK = 'OK',
  BANK_TRANSFER_ERROR = 'BANK_TRANSFER_ERROR',
}

/**
 * DummyBankAccount
 * A dummy bank account used for testing.
 */
export interface DummyBankAccount {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account ID
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  /**
   * Account Id
   * The account number of the dummy bank account
   */
  account_id: string;
  /**
   * Display Account Number
   * The display name of the dummy bank account
   */
  display_account_number: string;
  /**
   * The type of the dummy bank account
   * @default "CHECKING"
   */
  account_type?: BankingAccountTypeEnum;
  /**
   * Available Balance
   * The available balance of the dummy bank account
   * @default "0"
   */
  available_balance?: string;
  /**
   * Account Description
   * The description of the dummy bank account
   * @default "Account Description"
   */
  account_description?: string;
}

/** EducationContent */
export interface EducationContent {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /** Data Type */
  data_type: string;
  /** Version */
  version: string;
  /** Id */
  id: string;
  /** Content */
  content: string;
  /** Section */
  section: string;
  /** Header */
  header: string;
  /**
   * Token Name
   * Cryptocurrency abbreviated name
   */
  token_name?: string | null;
  /** Order In Section */
  order_in_section: number;
}

/**
 * EducationContentPaginator
 * Holds list of education docs and pagination fields
 */
export interface EducationContentPaginator {
  /** Items */
  items: EducationContent[];
  last_evaluated_key?: LastEvaluatedKey | null;
  /**
   * Per Page
   * Maximum items to return / returned per page
   */
  per_page: number;
  /**
   * Has More
   *
   *     More items are available to be retrieved using the last_evaluated_key fields
   *
   * @default false
   */
  has_more?: boolean;
}

/**
 * EducationTypeEnum
 * Enum of education types.
 */
export enum EducationTypeEnum {
  CRYPTOFI = 'CRYPTOFI',
  INVESTIFI = 'INVESTIFI',
}

/**
 * EnabledAssets
 * Enabled assets for a financial institution.
 *
 * All assets are disabled by default (default=False) to require explicit
 * enabling of each asset for the financial institution.
 */
export interface EnabledAssets {
  /**
   * Bch
   * Whether to enable BCH.
   * @default false
   */
  BCH?: boolean;
  /**
   * Btc
   * Whether to enable BTC.
   * @default false
   */
  BTC?: boolean;
  /**
   * Eth
   * Whether to enable ETH.
   * @default false
   */
  ETH?: boolean;
  /**
   * Ltc
   * Whether to enable LTC.
   * @default false
   */
  LTC?: boolean;
  /**
   * Xlm
   * Whether to enable XLM.
   * @default false
   */
  XLM?: boolean;
  /**
   * Xrp
   * Whether to enable XRP.
   * @default false
   */
  XRP?: boolean;
  /**
   * Link
   * Whether to enable LINK.
   * @default false
   */
  LINK?: boolean;
  /**
   * Avax
   * Whether to enable AVAX.
   * @default false
   */
  AVAX?: boolean;
  /**
   * Algo
   * Whether to enable ALGO.
   * @default false
   */
  ALGO?: boolean;
  /**
   * Mkr
   * Whether to enable MKR.
   * @default false
   */
  MKR?: boolean;
  /**
   * Doge
   * Whether to enable DOGE.
   * @default false
   */
  DOGE?: boolean;
  /**
   * Sol
   * Whether to enable SOL.
   * @default false
   */
  SOL?: boolean;
}

/**
 * EnabledFeatureSet
 * Model for the investifi features an FI has enabled with regards to crypto and securities trading.
 */
export interface EnabledFeatureSet {
  /**
   * Enabled assets for a financial institution.
   *
   * All assets are disabled by default (default=False) to require explicit
   * enabling of each asset for the financial institution.
   */
  enabled_assets: EnabledAssets;
  enabled_securities: EnabledSecurities;
  enabled_securities_transaction_types: EnabledSecuritiesTransactionTypes;
  /** Is Crypto Enabled */
  is_crypto_enabled: boolean;
  /** Is Securities Enabled */
  is_securities_enabled: boolean;
  /** Is Robo Enabled */
  is_robo_enabled: boolean;
}

/** EnabledSecurities */
export interface EnabledSecurities {
  /**
   * Equities
   * Flag for if equities are enabled for the FI
   * @default false
   */
  equities?: boolean;
  /**
   * Etfs
   * Flag for if etfs are enabled for the FI
   * @default false
   */
  etfs?: boolean;
  /**
   * Options
   * Flag for if options are enabled for the FI
   * @default false
   */
  options?: boolean;
}

/** EnabledSecuritiesTransactionTypes */
export interface EnabledSecuritiesTransactionTypes {
  /**
   * Market
   * @default false
   */
  market?: boolean;
  /**
   * Limit
   * @default false
   */
  limit?: boolean;
  /**
   * Stop
   * @default false
   */
  stop?: boolean;
  /**
   * Stop Limit
   * @default false
   */
  stop_limit?: boolean;
  /**
   * Trailing Stop
   * @default false
   */
  trailing_stop?: boolean;
}

/**
 * ExtendedTradingPeriodResponseModel
 * TradingPeriod response model with additional properties that
 * are intentionally suppressed by default in the parent as they are
 * computationally expensive to calculate when many TradingPeriods
 * are fetched.
 *
 * This model is only to be used as a return type for the (singular)
 * TradingPeriod detail endpoint.
 */
export interface ExtendedTradingPeriodResponseModel {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * Start Time
   * @format date-time
   */
  start_time: string;
  /**
   * End Time
   * @format date-time
   */
  end_time: string;
  /** Trading Period Id */
  trading_period_id?: string | null;
  /**
   * Crypto TradingPeriods nested within a TradingPeriod.
   * @default {"status":"open","total_transaction_volume":"0","total_transaction_fees":"0"}
   */
  crypto_trading_period?: CryptoTradingPeriod;
  /**
   * Securities TradingPeriods nested within a TradingPeriod.
   * @default {"sell_side":{"received_dividend_proceeds_ach_push":false,"received_sell_proceeds_ach_push":false,"status":"open","total_transaction_fees":"0","total_transaction_volume":"0"},"buy_side":{"status":"open","total_transaction_fees":"0","total_transaction_volume":"0"},"total_transaction_volume":"0","total_transaction_fees":"0"}
   */
  securities_trading_period?: SecuritiesTradingPeriod;
  /**
   * Excluded Properties
   * @default ["orders","_dyntastic_key_dict","_dyntastic_hash_key"]
   */
  EXCLUDED_PROPERTIES?: any[];
  /**
   * There are only three "macro" states for the parent TradingPeriod:
   * - OPEN
   * - PENDING
   * - FULLY_SETTLED
   * This field is a generalized representation of the underlying state of the nested
   * Crypto + Securities TradingPeriods.
   */
  status: MacroTradingPeriodStatus;
  /**
   * Total Transaction Volume
   * Return the total transaction volume for the TradingPeriod.
   */
  total_transaction_volume: string;
  /**
   * Total Transaction Fees
   * Return the total transaction fees for the TradingPeriod.
   */
  total_transaction_fees: string;
  /** Is Buy Day */
  is_buy_day: boolean;
  /** Is Sell Day */
  is_sell_day: boolean;
  /** Net Settlement Amount */
  net_settlement_amount: string;
  /** Net Crypto Amount */
  net_crypto_amount: string;
}

/**
 * FeeBreakdown
 * Fee Breakdown that represents amounts computed in a buy or sell transaction
 */
export interface FeeBreakdown {
  /**
   * Tx Net
   *
   *         When buying, tx_net represents the value to send to the LP to purchase
   *         and in this case represents the amount the buyer put forth MINUS THE FEES.
   *         for example, buyer spends $20 total to buy ETH, fee is $1.49 then when placing the order
   *         this amount will be $18.51 and that is what will be sent to the LP to place the buy
   *         and the LP should buy as much ETH as possible for that $18.51
   *
   *         When selling, tx_net represents the amount received after fees subtracted
   *         and in this case represents the total AMOUNT DUE BACK TO SELLER.
   *         for example, seller sells x ETH and the LP gets $20 market value,
   *         however, this is the total the LP got for the sale of ETH and fees need
   *         to be applied of $1.49 by subtracting from the total and seller will be due back $18.51
   *
   */
  tx_net: string;
  /**
   * Tx Gross
   *
   *         When buying tx_gross represents the total amount user paid including fees.
   *         When selling tx_gross represents the total amount of the sale before fees taken out.
   *
   */
  tx_gross: string;
  /**
   * Tx Fee
   *
   *         Represents the total monetary value of the fee applied in transaction.
   *
   */
  tx_fee: string;
  /**
   *
   *     Represents currency used to in the transaction that the buyer paid on a buy or seller is due
   *     on a sale.
   *
   */
  tx_currency: CurrencyEnum;
  /**
   * Represents the fee item that is used to determine the fee type and amount
   * that should be applied for an order and is used in linking the price paid
   * or price sold to a fee record that indicates what the fee amount will be.
   * See these requirements on how the fees should be modeled and applied
   * https://drive.google.com/file/d/10T7-bwFrn4FgwWrox57pjmFVvwuZ7Olk/view?usp=share_link
   */
  applicable_fee_item: FeeItemOutput;
}

/**
 * FeeItem
 * Represents the fee item that is used to determine the fee type and amount
 * that should be applied for an order and is used in linking the price paid
 * or price sold to a fee record that indicates what the fee amount will be.
 * See these requirements on how the fees should be modeled and applied
 * https://drive.google.com/file/d/10T7-bwFrn4FgwWrox57pjmFVvwuZ7Olk/view?usp=share_link
 */
export interface FeeItemInput {
  /**
   * Start Price
   *
   *     Price that order must be greater than >
   *
   */
  start_price: number | string;
  /**
   * End Price
   *
   *     Price that order must also be less than or equal to <=
   *     If no end_price is set then will represent the final record in the structure
   *     and any prices greater than the start price shall match regardless
   *
   */
  end_price?: number | string | null;
  /**
   *
   *     Represents the method to use in applying the fee such as use a percentage
   *     calculation or take apply the amount of the fee directly (flat)
   *
   */
  fee_type?: FeeTypeEnum;
  /**
   * Fee Amount
   *
   *     Represents the amount of the fee and depending on the fee type this field
   *     will contain a percentage to apply in calculating the fee, or it could hold
   *     a static flat value to apply
   *
   */
  fee_amount?: number | string;
}

/**
 * FeeItem
 * Represents the fee item that is used to determine the fee type and amount
 * that should be applied for an order and is used in linking the price paid
 * or price sold to a fee record that indicates what the fee amount will be.
 * See these requirements on how the fees should be modeled and applied
 * https://drive.google.com/file/d/10T7-bwFrn4FgwWrox57pjmFVvwuZ7Olk/view?usp=share_link
 */
export interface FeeItemOutput {
  /**
   * Start Price
   *
   *     Price that order must be greater than >
   *
   */
  start_price: string;
  /**
   * End Price
   *
   *     Price that order must also be less than or equal to <=
   *     If no end_price is set then will represent the final record in the structure
   *     and any prices greater than the start price shall match regardless
   *
   */
  end_price?: string | null;
  /**
   *
   *     Represents the method to use in applying the fee such as use a percentage
   *     calculation or take apply the amount of the fee directly (flat)
   *
   */
  fee_type?: FeeTypeEnum;
  /**
   * Fee Amount
   *
   *     Represents the amount of the fee and depending on the fee type this field
   *     will contain a percentage to apply in calculating the fee, or it could hold
   *     a static flat value to apply
   *
   */
  fee_amount?: string;
}

/**
 * FeeTypeEnum
 * List of current Fee types
 */
export enum FeeTypeEnum {
  FLAT = 'FLAT',
  PERCENTAGE = 'PERCENTAGE',
}

/**
 * FinancialInstitution
 * todo: review the ops center required fields and remove anything unused
 */
export interface FinancialInstitutionInput {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * General settings of Financial Institution
   * todo: review the ops center required fields and remove anything unused
   */
  general_settings?: FinancialInstitutionGeneralSettings;
  /** Contains links to the FI's investifi terms and conditions */
  terms_and_conditions?: FinancialInstitutionInvestifiTermsAndConditionsInput;
  /**
   * Education Content
   * @default {}
   */
  education_content?: object | null;
  /** The FI's banking core name */
  core_name?: BankingCoreEnum | null;
  /** The api used for interacting with the Fi's banking core */
  core_client_name?: BankingCoreClientEnum | null;
  custodian_name?: CustodianEnum | null;
  liquidity_provider_name?: LiquidityProviderEnum | null;
  /** The name of the security provider for the FI (if applicable) */
  securities_provider_name?: SecuritiesProviderEnum | null;
  /**
   * Deposit Accounts
   * @default {}
   */
  deposit_accounts?: object | null;
  /** Enabled assets for a financial institution. */
  enabled_assets?: EnabledAssets;
  /** Fee Range */
  fee_range?: Record<string, FeeItemInput[]> | null;
  /** Transaction Limits for the FI */
  transaction_limits?: FinancialInstitutionTransactionLimitsInput;
  /**
   * Timezone
   * @default "America/New_York"
   */
  timezone?: string;
  /** Trading period configuration for a financial institution. */
  trading_period_config?: TradingPeriodConfig;
  /**
   * Is System Active
   * Flag for enabling/disabling core app functionality. When this is False it's usually due to downtime at one of our integration partners
   * @default true
   */
  is_system_active?: boolean;
  /**
   * Is Securities System Active
   * Flag for enabling/disabling securities system functionality. When this is False it's usually due to downtime at one of our integration partners
   * @default true
   */
  is_securities_system_active?: boolean;
  /**
   * Scheduled Downtimes
   * List of FI's Scheduled Downtimes
   * @default []
   */
  scheduled_downtimes?: FinancialInstitutionScheduledDowntime[];
  /**
   * Supported Account Types
   * List of eligible user types for the FI
   * @default ["INDIVIDUAL"]
   */
  supported_account_types?: BankingUserMemberTypeEnum[] | null;
  /** A Mixpanel Chart Grouping for the FI. This is a list of URLs that should be included in the Mixpanel Chart Grouping. */
  mixpanel_chart_grouping?: MixpanelChartGrouping;
  /**
   * Recon Email Addresses
   * List of email addresses to send reconciliation emails to
   */
  recon_email_addresses?: string[] | null;
  /** The FI's crypto settlement account */
  crypto_settlement_account?: FinancialInstitutionCoreSettlementAccount | null;
  /** The FI's securities settlement account */
  securities_settlement_account?: FinancialInstitutionCoreSettlementAccount | null;
}

/**
 * FinancialInstitution
 * todo: review the ops center required fields and remove anything unused
 */
export interface FinancialInstitutionOutput {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * General settings of Financial Institution
   * todo: review the ops center required fields and remove anything unused
   */
  general_settings?: FinancialInstitutionGeneralSettings;
  /** Contains links to the FI's investifi terms and conditions */
  terms_and_conditions?: FinancialInstitutionInvestifiTermsAndConditionsOutput;
  /**
   * Education Content
   * @default {}
   */
  education_content?: object | null;
  /** The FI's banking core name */
  core_name?: BankingCoreEnum | null;
  /** The api used for interacting with the Fi's banking core */
  core_client_name?: BankingCoreClientEnum | null;
  custodian_name?: CustodianEnum | null;
  liquidity_provider_name?: LiquidityProviderEnum | null;
  /** The name of the security provider for the FI (if applicable) */
  securities_provider_name?: SecuritiesProviderEnum | null;
  /**
   * Deposit Accounts
   * @default {}
   */
  deposit_accounts?: object | null;
  /** Enabled assets for a financial institution. */
  enabled_assets?: EnabledAssets;
  /** Fee Range */
  fee_range?: Record<string, FeeItemOutput[]> | null;
  /** Transaction Limits for the FI */
  transaction_limits?: FinancialInstitutionTransactionLimitsOutput;
  /**
   * Timezone
   * @default "America/New_York"
   */
  timezone?: string;
  /** Trading period configuration for a financial institution. */
  trading_period_config?: TradingPeriodConfig;
  /**
   * Is System Active
   * Flag for enabling/disabling core app functionality. When this is False it's usually due to downtime at one of our integration partners
   * @default true
   */
  is_system_active?: boolean;
  /**
   * Is Securities System Active
   * Flag for enabling/disabling securities system functionality. When this is False it's usually due to downtime at one of our integration partners
   * @default true
   */
  is_securities_system_active?: boolean;
  /**
   * Scheduled Downtimes
   * List of FI's Scheduled Downtimes
   * @default []
   */
  scheduled_downtimes?: FinancialInstitutionScheduledDowntime[];
  /**
   * Supported Account Types
   * List of eligible user types for the FI
   * @default ["INDIVIDUAL"]
   */
  supported_account_types?: BankingUserMemberTypeEnum[] | null;
  /** A Mixpanel Chart Grouping for the FI. This is a list of URLs that should be included in the Mixpanel Chart Grouping. */
  mixpanel_chart_grouping?: MixpanelChartGrouping;
  /**
   * Recon Email Addresses
   * List of email addresses to send reconciliation emails to
   */
  recon_email_addresses?: string[] | null;
  /** The FI's crypto settlement account */
  crypto_settlement_account?: FinancialInstitutionCoreSettlementAccount | null;
  /** The FI's securities settlement account */
  securities_settlement_account?: FinancialInstitutionCoreSettlementAccount | null;
  /**
   * Otp Verification Required
   * A computed field (not stored in DB) to determine if OTP verification is required for the FI during user onboarding
   *
   * !!!!
   * NOTE: currently this is unused as sFox is the only FI that required OTP verification but they removed it
   * However, the frontend still has logic to support otp codes via this flag, thus we should leave it in here
   * should the need every arise in the future to reimplement or a corresponding FE change is made in order to remove this logic there too
   * !!!!
   */
  otp_verification_required: boolean;
  /**
   * Is Crypto Enabled
   * Returns True when the FI has crypto trading enabled.
   */
  is_crypto_enabled: boolean;
  /**
   * Is Securities Enabled
   * Returns True when the FI has securities trading enabled.
   */
  is_securities_enabled: boolean;
  /**
   * Is Robo Enabled
   * Returns True when the FI has robo advisor enabled.
   */
  is_robo_enabled: boolean;
  /**
   * Uses Custom Robo Portfolios
   * Returns True when the FI manages their own Robo Portfolios.
   */
  uses_custom_robo_portfolios: boolean;
  enabled_securities: EnabledSecurities;
  enabled_securities_transaction_types: EnabledSecuritiesTransactionTypes;
}

/**
 * FinancialInstitutionAdminUsersPaginator
 * Holds list of admin users per FI
 */
export interface FinancialInstitutionAdminUsersPaginator {
  /** Items */
  items: AdminUser[];
  /**
   * Pagination Token
   *
   *     When set this indicates there are more results to get and specifies the pagination_token to pass in to get the next set of results
   *
   */
  pagination_token: string | null;
}

/**
 * FinancialInstitutionCoreClient
 * todo: review the ops center required fields and remove anything unused
 */
export interface FinancialInstitutionCoreClient {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /** List of supported Banking Cores */
  core_name: BankingCoreEnum;
  /**
   * List of supported Banking Core Clients
   * These are essentially APIs that interact with several banking cores
   */
  core_client_name: BankingCoreClientEnum;
  /** Settings */
  settings:
    | ({
        core_client_name: 'CuAnswers';
      } & FinancialInstitutionCoreClientCuAnswersSettings)
    | ({
        core_client_name: 'Dummy';
      } & FinancialInstitutionCoreClientDummySettings)
    | ({
        core_client_name: 'Jxchange';
      } & FinancialInstitutionCoreClientJxchangeSettings)
    | ({
        core_client_name: 'Ncr';
      } & FinancialInstitutionCoreClientNcrSettings)
    | ({
        core_client_name: 'Q2';
      } & FinancialInstitutionCoreClientQ2Settings)
    | ({
        core_client_name: 'SymXchange';
      } & FinancialInstitutionCoreClientSymXchangeSettings);
}

/**
 * FinancialInstitutionCoreClientCuAnswersSettings
 * Core Client Settings for CU Answers
 */
export interface FinancialInstitutionCoreClientCuAnswersSettings {
  /**
   * Secret Name Format
   * @default "cws-api/core-client-cu-answers/credentials-fi-{}"
   */
  secret_name_format?: string;
  /**
   * Core Client Name
   * @default "CuAnswers"
   */
  core_client_name?: 'CuAnswers';
  /**
   * Acct Types
   * @default ["CHECKING","SAVINGS"]
   */
  acct_types?: BankingCoreClientCuAnswersAccountTypesEnum[];
  /**
   * Url
   * Url for CU Answers rest endpoint.
   */
  url: string;
  /**
   * Subdomain
   * CU Answer's subdomain for the FI. This should be the same as the subdomain for the IDP Settings.
   */
  subdomain?: string | null;
  /**
   * Entity Key
   * CU*Answers unique credit union identifier (CU00000-CU99999)
   */
  entity_key: string;
}

/**
 * FinancialInstitutionCoreClientDummySettings
 * Core client settings for a dummy core client.
 */
export interface FinancialInstitutionCoreClientDummySettings {
  /**
   * Core Client Name
   * @default "Dummy"
   */
  core_client_name?: 'Dummy';
}

/** FinancialInstitutionCoreClientJxchangeSettings */
export interface FinancialInstitutionCoreClientJxchangeSettings {
  /**
   * Core Client Name
   * @default "Jxchange"
   */
  core_client_name?: 'Jxchange';
}

/**
 * FinancialInstitutionCoreClientNcrSecrets
 * Pydantic Base Model Class for storing NCR's client secrets in AWS Secrets Manager
 * NOTE: This model does not save to dynamodb but is used for validation of inputs and then
 * uploading the secret to AWS Secrets Manager
 */
export interface FinancialInstitutionCoreClientNcrSecrets {
  /**
   * Secret Name Format
   * Format for the secret name
   */
  secret_name_format: string;
  /** Client Id */
  client_id: string;
  /** Client Secret */
  client_secret: string;
}

/** FinancialInstitutionCoreClientNcrSettings */
export interface FinancialInstitutionCoreClientNcrSettings {
  /**
   * Secret Name Format
   * @default "cws-api/core-client-ncr/credentials-fi-{}"
   */
  secret_name_format?: string;
  /**
   * Core Client Name
   * @default "Ncr"
   */
  core_client_name?: 'Ncr';
  /**
   * Acct Types
   * @default ["CHECKING","SAVINGS"]
   */
  acct_types?: BankingCoreClientNcrAccountTypesEnum[];
  /**
   * Url
   * Url for NCR rest endpoint. This is the base url as well as the /digitalbanking/ path parameter, and optionally /stage/ for staging environments
   */
  url: string;
  /**
   * Ncr Fi Id
   * NCR's ID for the FI
   */
  ncr_fi_id: string;
  /**
   * Subdomain
   * NCR's subdomain for the FI. This should be the same as the subdomain for the IDP Settings.
   */
  subdomain?: string | null;
}

/**
 * FinancialInstitutionCoreClientQ2Settings
 * Core client settings for a Q2 core client.
 */
export interface FinancialInstitutionCoreClientQ2Settings {
  /**
   * Secret Name Format
   * @default "cws-api/core-client-q2/credentials-fi-{}"
   */
  secret_name_format?: string;
  /**
   * Core Client Name
   * @default "Q2"
   */
  core_client_name?: 'Q2';
  /**
   * Url
   * Url for Q2 rest endpoint.
   */
  url: string;
  /**
   * Acct Types
   * @default ["Q2 BASIC CHECKING","Q2 VALUE CHECKING","Q2 PREMIER CHECKING","Q2 ULTIMATE CHECKING","Q2 VALUE SAVINGS","Q2 VALUE SAVINGS","Q2 PREMIER SAVINGS","Q2 ULTIMATE SAVINGS","TOTAL ACCESS SAVINGS","TOTAL ACCESS RETAIL CHECKING"]
   */
  acct_types?: BankingCoreClientQ2AccountTypesEnum[];
  /**
   * Caliper Api Token Url
   * Url for Q2 Caliper API token endpoint.
   */
  caliper_api_token_url: string;
  /**
   * Caliper Api Url
   * Url for Q2 Caliper API endpoint.
   */
  caliper_api_url: string;
}

/** FinancialInstitutionCoreClientSymXchangeSettings */
export interface FinancialInstitutionCoreClientSymXchangeSettings {
  /**
   * Secret Name Format
   * @default "cws-api/core-client-symxchange/credentials-fi-{}"
   */
  secret_name_format?: string;
  /**
   * Core Client Name
   * @default "SymXchange"
   */
  core_client_name?: 'SymXchange';
  /**
   * Acct Types
   * @default ["CHECKING","SAVINGS"]
   */
  acct_types?: BankingCoreClientSymXchangeAccountTypesEnum[];
  /**
   * Share Mappings
   * List of share mappings for the FI. This maps the share (a.k.a. bank account) type to a checking account Enum.
   * @default {}
   */
  share_mappings?: Record<string, FinancialInstitutionShareMapping> | null;
  /**
   * Member Account Code Mappings
   * List of member account type mappings for the FI. This maps the member account type (Member, Employee, LLC) to a KYC application type Enum
   * @default {}
   */
  member_account_code_mappings?: Record<string, FinancialInstitutionMemberAccountCodeMapping> | null;
  /**
   * Crypto Buy Comment Code
   * CommentCode value included in TransactionsService.withdraw requests for crypto buys
   * @default ""
   */
  crypto_buy_comment_code?: number | null;
  /**
   * Crypto Sell Comment Code
   * CommentCode value included in TransactionsService.deposit requests for crypto sells
   * @default ""
   */
  crypto_sell_comment_code?: number | null;
  /**
   * Refund Comment Code
   * CommentCode value included in TransactionsService.deposit requests for refunds
   * @default ""
   */
  refund_comment_code?: number | null;
  /**
   * Securities Buy Comment Code
   * CommentCode value included in TransactionsService.withdraw requests for crypto buys
   * @default ""
   */
  securities_buy_comment_code?: number | null;
  /**
   * Securities Sell Comment Code
   * CommentCode value included in TransactionsService.deposit requests for crypto sells
   * @default ""
   */
  securities_sell_comment_code?: number | null;
  /**
   * Securities Refund Comment Code
   * CommentCode value included in TransactionsService.deposit requests for refunds
   * @default ""
   */
  securities_refund_comment_code?: number | null;
  /**
   * Securities Dividend Comment Code
   * CommentCode value included in TransactionsService.deposit requests for dividends
   * @default ""
   */
  securities_dividend_comment_code?: number | null;
}

/** FinancialInstitutionCoreSettlementAccount */
export interface FinancialInstitutionCoreSettlementAccount {
  /** Account Id */
  account_id: string;
  /**
   * Aba Routing Number
   * ABA Routing Number for the account. Required for ACH.
   */
  aba_routing_number?: string | null;
  /** Account Type */
  account_type: string;
  /** Batch Number */
  batch_number?: string | null;
  /**
   * General Ledger
   * @default false
   */
  general_ledger?: boolean;
}

/** FinancialInstitutionCustodian */
export interface FinancialInstitutionCustodian {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /** List of supported Custodians */
  custodian_name: CustodianEnum;
  /**
   * Settings
   * The Fi's granular custodian settings
   */
  settings:
    | ({
        custodian_name: 'DummyCustodian';
      } & FinancialInstitutionCustodianDummySettings)
    | ({
        custodian_name: 'sFOX';
      } & FinancialInstitutionCustodianSfoxSettings);
}

/** FinancialInstitutionCustodianDummySettings */
export interface FinancialInstitutionCustodianDummySettings {
  /**
   * Custodian Name
   * @default "DummyCustodian"
   */
  custodian_name?: 'DummyCustodian';
  /**
   * Settings
   * @default {}
   */
  settings?: object;
}

/**
 * FinancialInstitutionCustodianSfoxSettings
 * Model to satisfy FI-Onboarding requirements.
 * Note that this has no fields defined. This is because sFOX
 * is not configured at an FI-level.
 */
export interface FinancialInstitutionCustodianSfoxSettings {
  /**
   * Secret Name Format
   * @default "cws-api/sfox/api-key"
   */
  secret_name_format?: string;
  /**
   * Custodian Name
   * @default "sFOX"
   */
  custodian_name?: 'sFOX';
}

/**
 * FinancialInstitutionEducationContent
 * This model persists education content (FIEducationSections) for
 * a specific FI.
 */
export interface FinancialInstitutionEducationContent {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /** The product the education section is for (InvestiFi / CryptoFi). */
  education_type: EducationTypeEnum;
  /**
   * Sections
   * A list of FI-specific education sections.
   * @default []
   */
  sections?: FinancialInstitutionEducationContentSection[];
}

/**
 * FinancialInstitutionEducationContentSection
 * These are FI specific education sections. For each FI, these
 * model instances will be a copy of the list of global default education
 * sections. Each FI may then optionally disable these and/or create their
 * own.
 */
export interface FinancialInstitutionEducationContentSection {
  /**
   * Uuid
   * UUID of the section.
   */
  uuid?: string;
  /**
   * Title
   * Title of the section.
   */
  title: string;
  /**
   * Image
   * Image URL of the section.
   * @default ""
   */
  image?: string | null;
  /**
   * Topics
   * Topics of the FI section.
   */
  topics: FinancialInstitutionEducationContentTopic[];
  /**
   * Enabled
   * @default true
   */
  enabled?: boolean;
  /**
   * Reference To Global Default
   * An optional reference to a global default EducationSection if this instance was copied from a global default.
   */
  reference_to_global_default?: string | null;
}

/**
 * FinancialInstitutionEducationContentTopic
 * These are FI specific education topics - They live within a `Section`.
 */
export interface FinancialInstitutionEducationContentTopic {
  /**
   * Uuid
   * UUID of the topic.
   */
  uuid?: string;
  /**
   * Title
   * Title of the topic.
   */
  title: string;
  /**
   * Image
   * Image URL of the topic.
   * @default ""
   */
  image?: string | null;
  /**
   * Body
   * Body of the topic.
   */
  body: string;
  /**
   * Enabled
   * @default true
   */
  enabled?: boolean;
  /**
   * Reference To Global Default
   * An optional reference to a global default EducationSection if this instance was copied from a global default.
   */
  reference_to_global_default?: string | null;
}

/**
 * FinancialInstitutionGeneralSettings
 * General settings of Financial Institution
 * todo: review the ops center required fields and remove anything unused
 */
export interface FinancialInstitutionGeneralSettings {
  /** Name */
  name?: string | null;
  /** Institution Id */
  institution_id?: string | null;
  /**
   * The Type of Financial Institution
   * @default "CREDIT_UNION"
   */
  fi_type?: FinancialInstitutionTypeEnum | null;
}

/**
 * FinancialInstitutionGeneralSettingsRequest
 * General Settings Update Request for a Financial Institution
 * We want to make sure that the Name is always required and the user cannot update the fi_id
 */
export interface FinancialInstitutionGeneralSettingsRequest {
  /**
   * Name
   * The name of the Financial Institution
   */
  name: string;
  /**
   * The Type of Financial Institution
   * @default "CREDIT_UNION"
   */
  fi_type?: FinancialInstitutionTypeEnum;
}

/**
 * FinancialInstitutionInfoAdminResponse
 * Response model for the financial institution info endpoint in "admin" authenticated routes (i.e. ops-center).
 */
export interface FinancialInstitutionInfoAdminResponse {
  /**
   * Fi Name
   * FI's General Settings/Info
   * @default ""
   */
  fi_name?: string;
  /** A Mixpanel Chart Grouping for the FI. This is a list of URLs that should be included in the Mixpanel Chart Grouping. */
  mixpanel_chart_grouping: MixpanelChartGrouping;
  /** Enabled assets for a financial institution. */
  enabled_assets: EnabledAssets;
  /** Is Crypto Enabled */
  is_crypto_enabled: boolean;
  /** Is Securities Enabled */
  is_securities_enabled: boolean;
  /** Investifi features an FI has enabled with regards to crypto and securities trading. */
  enabled_feature_set: EnabledFeatureSet;
}

/**
 * FinancialInstitutionInfoUserResponse
 * Response model for the financial institution info endpoint in "user" authenticated routes (i.e. dbp-frontend).
 * This will help the frontend determine which FI Type to display and the FI Name
 */
export interface FinancialInstitutionInfoUserResponse {
  /**
   * Fi Name
   * The name of the financial institution
   */
  fi_name: string;
  /** The type of the financial institution */
  fi_type: FinancialInstitutionTypeEnum;
  fi_custodian_name?: CustodianEnum | null;
  fi_securities_provider_name?: SecuritiesProviderEnum | null;
  /**
   * Cryptofi Education Content
   * Cryptofi education content for a financial institution.
   */
  cryptofi_education_content: FinancialInstitutionEducationContentSection[];
  /** Investifi features an FI has enabled with regards to crypto and securities trading. */
  enabled_feature_set: EnabledFeatureSet;
  /**
   * Otp Verification Required
   * Whether OTP verification is required for the FI during onboarding
   */
  otp_verification_required: boolean;
  /** Terms and conditions urls for the FI */
  terms_and_conditions?: FinancialInstitutionTermsAndConditionsUserResponse;
  /** Transaction limits for the FI. */
  transaction_limits: FinancialInstitutionTransactionLimitsOutput;
}

/**
 * FinancialInstitutionInvestifiTermsAndConditions
 * Financial institution specific terms and conditions related to Investifi
 * These terms and conditions are displayed/accepted during both onboarding processes
 */
export interface FinancialInstitutionInvestifiTermsAndConditionsInput {
  /**
   * Date Updated
   * Date the user record last updated
   */
  date_updated?: string | null;
  /**
   * Version
   * The version of the terms and conditions
   */
  version?: string | null;
  /**
   * Description
   * Description to display under the terms and conditions check box.
   */
  description?: string | null;
  /** The URL information for atribution clause. If provided, it will have its own checkbox */
  arbitration_clause?: UrlLink | null;
  /** Dictionary of UrlLinks for legal documents */
  policies?: BaseTermsAndConditionsUrls;
  /**
   * The type of terms and conditions
   * @default "investifi"
   */
  terms_type?: TermsAndConditionTypes;
}

/**
 * FinancialInstitutionInvestifiTermsAndConditions
 * Financial institution specific terms and conditions related to Investifi
 * These terms and conditions are displayed/accepted during both onboarding processes
 */
export interface FinancialInstitutionInvestifiTermsAndConditionsOutput {
  /**
   * Date Updated
   * Date the user record last updated
   */
  date_updated?: string | null;
  /**
   * Version
   * The version of the terms and conditions
   */
  version?: string | null;
  /**
   * Description
   * Description to display under the terms and conditions check box.
   */
  description?: string | null;
  /** The URL information for atribution clause. If provided, it will have its own checkbox */
  arbitration_clause?: UrlLink | null;
  /** Dictionary of UrlLinks for legal documents */
  policies?: BaseTermsAndConditionsUrls;
  /**
   * The type of terms and conditions
   * @default "investifi"
   */
  terms_type?: TermsAndConditionTypes;
}

/** FinancialInstitutionLiquidityProvider */
export interface FinancialInstitutionLiquidityProvider {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /** List of supported Liquidity Providers */
  liquidity_provider_name: LiquidityProviderEnum;
  /**
   * Is Active
   * Flag for if the liquidity provider is currently active/available to facilitate trading
   * @default true
   */
  is_active?: boolean;
  /**
   * Settings
   * The Fi's granular liquidity provider settings
   */
  settings:
    | ({
        liquidity_provider_name: 'DummyLP';
      } & FinancialInstitutionLiquidityProviderDummySettings)
    | ({
        liquidity_provider_name: 'sFOX';
      } & FinancialInstitutionLiquidityProviderSfoxSettings);
}

/** FinancialInstitutionLiquidityProviderDummySettings */
export interface FinancialInstitutionLiquidityProviderDummySettings {
  /**
   * Api Key
   * Dummy Api Key
   */
  api_key?: string;
  /**
   * Liquidity Provider Name
   * @default "DummyLP"
   */
  liquidity_provider_name?: 'DummyLP';
}

/**
 * FinancialInstitutionLiquidityProviderSfoxSettings
 * Model to satisfy FI-Onboarding requirements.
 * Note that this has no fields defined. This is because sFOX
 * is not configured at an FI-level.
 */
export interface FinancialInstitutionLiquidityProviderSfoxSettings {
  /**
   * Secret Name Format
   * @default "cws-api/sfox/api-key"
   */
  secret_name_format?: string;
  /**
   * Liquidity Provider Name
   * @default "sFOX"
   */
  liquidity_provider_name?: 'sFOX';
}

/**
 * FinancialInstitutionMemberAccountCodeMapping
 * Model for storing member account code mappings for a Financial Institution.
 * This will map a member account code (an int) to the member's KYC application type Enum, options INDIVIDUAL and ORG.
 * Example: UFCU Employee code: 0014  => INDIVIDUAL
 *          UFCU LLC code: 0011  => ORG
 * The description is optional and is used to describe the type of member account code being mapped for better understanding.
 */
export interface FinancialInstitutionMemberAccountCodeMapping {
  /**
   * Description
   * Description of the member account code mapping.
   */
  description: string | null;
  /** The KYC application type Enum that the member account code is mapped to. */
  member_type: KYCApplicationTypeEnum;
}

/**
 * FinancialInstitutionScheduledDowntime
 * Model for storing FinancialInstitution's scheduled downtimes based on their LP/Custodian setup
 * These values are used to dynamically turn the system off/on via an event rule in the fi_stack
 * Additionally these values are used in endpoints the frontend queries for system status, providing
 * warning messages if the system is about to go down, or downtime messages for the frontend to display
 */
export interface FinancialInstitutionScheduledDowntime {
  /**
   * Service Name
   * Name of the service that the downtime effects
   */
  service_name?: string;
  /**
   * Start Time Utc
   * @pattern ^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$
   */
  start_time_utc: string;
  /**
   * End Time Utc
   * @pattern ^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$
   */
  end_time_utc: string;
  /**
   * Day Of Week
   * Day of the week downtime occurs. If None, it is assumed its everyday
   */
  day_of_week?: string | null;
  /**
   * Warning Message
   * Message to display prior to downtime occuring
   */
  warning_message?: string | null;
  /**
   * Downtime Message
   * Message to display during downtime
   */
  downtime_message?: string | null;
}

/**
 * FinancialInstitutionShareMapping
 * Model for storing share mappings for a Financial Institution.
 * A share is a Credit Union version of a bank account.
 * This will map the shares type (an int) to a checking account Enum.
 * The description is optional and is used to describe the type of share being mapped for better understanding.
 */
export interface FinancialInstitutionShareMapping {
  /**
   * Description
   * Description of the share mapping.
   */
  description: string | null;
  /** The checking account Enum that the share type is mapped to. */
  account_type: BankingCoreClientSymXchangeAccountTypesEnum;
}

/**
 * FinancialInstitutionTermsAndConditionsUserResponse
 * Data model for the terms and conditions urls that will be displayed in checkboxes in the FE for a user to accept.
 * These are broken up into their respective "categories" representing different investifi products that have their own terms and conditions.
 */
export interface FinancialInstitutionTermsAndConditionsUserResponse {
  /** Data model for the individual terms and conditions sections that will be displayed in checkboxes in the FE for a user to accept. */
  securities?: IndividualTermsAndConditionsUserResponse;
  /** Data model for the individual terms and conditions sections that will be displayed in checkboxes in the FE for a user to accept. */
  crypto?: IndividualTermsAndConditionsUserResponse;
  /** Data model for the individual terms and conditions sections that will be displayed in checkboxes in the FE for a user to accept. */
  investifi?: IndividualTermsAndConditionsUserResponse;
  /** Data model for the individual terms and conditions sections that will be displayed in checkboxes in the FE for a user to accept. */
  investifi_brokerage_license?: IndividualTermsAndConditionsUserResponse;
  /** Data model for the individual terms and conditions sections that will be displayed in checkboxes in the FE for a user to accept. */
  advisifi_brokerage_license?: IndividualTermsAndConditionsUserResponse;
}

/**
 * FinancialInstitutionTransactionLimits
 * A model to store the transaction limits for an FI
 *
 * Note: Buy and Sell will be deprecated in the future, and be replaced by crypto and securities
 */
export interface FinancialInstitutionTransactionLimitsInput {
  /** The transaction limits for crypto. This filed matches OrderAssetTypeEnum.CRYPTO - this is important for order price validation in trading.py. */
  crypto?: TransactionTypeLimits;
  /** The transaction limits for securities. This filed matches OrderAssetTypeEnum.SECURITY - this is important for order price validation in trading.py. */
  security?: TransactionTypeLimits;
}

/**
 * FinancialInstitutionTransactionLimits
 * A model to store the transaction limits for an FI
 *
 * Note: Buy and Sell will be deprecated in the future, and be replaced by crypto and securities
 */
export interface FinancialInstitutionTransactionLimitsOutput {
  /** The transaction limits for crypto. This filed matches OrderAssetTypeEnum.CRYPTO - this is important for order price validation in trading.py. */
  crypto?: TransactionTypeLimits;
  /** The transaction limits for securities. This filed matches OrderAssetTypeEnum.SECURITY - this is important for order price validation in trading.py. */
  security?: TransactionTypeLimits;
}

/**
 * FinancialInstitutionTypeEnum
 * The type of Financial Institution, i.e. Bank, Credit Union, etc.
 */
export enum FinancialInstitutionTypeEnum {
  BANK = 'BANK',
  CREDIT_UNION = 'CREDIT_UNION',
}

/**
 * GlobalTermsAndConditionsResponse
 * Response model for terms and conditions endpoint
 */
export interface GlobalTermsAndConditionsResponse {
  /**
   * P Key
   * Primary key for the terms and conditions entry
   */
  p_key?: string | null;
  /**
   * S Key
   * Sort key for the terms and conditions entry
   */
  s_key?: string | null;
  /**
   * Date Updated
   * Date the terms and conditions were last updated
   */
  date_updated?: string | null;
  /**
   * Version
   * Version of the terms and conditions
   */
  version?: string | null;
  /**
   * Description
   * Description to display under the terms and conditions check box
   */
  description?: string | null;
  /** URL information for arbitration clause */
  arbitration_clause?: UrlLink | null;
  /** Dictionary of URLs for legal documents */
  policies?: PoliciesResponse;
  /**
   * Terms Type
   * Type of terms and conditions (e.g., crypto, securities)
   */
  terms_type?: string | null;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/**
 * IdentityProviderAuthenticationTypeEnum
 * The type of authentication used by the FI's DBP client.
 */
export enum IdentityProviderAuthenticationTypeEnum {
  OAUTH = 'OAUTH',
  OIDC = 'OIDC',
}

/**
 * IndividualTermsAndConditionsUserResponse
 * Data model for the individual terms and conditions sections that will be displayed in checkboxes in the FE for a user to accept.
 */
export interface IndividualTermsAndConditionsUserResponse {
  /**
   * Description
   * The description of the terms and conditions
   */
  description?: string | null;
  /** The URL information for atribution clause. If provided, it will have its own checkbox */
  arbitration_clause?: UrlLink | null;
  /**
   * Policies
   * @default []
   */
  policies?: UrlLink[] | null;
  /** Version */
  version?: string | null;
}

/**
 * InvestifiProductTypeEnum
 * List of InvestiFi product types used for KYC, terms acceptance, etc.
 */
export enum InvestifiProductTypeEnum {
  Crypto = 'crypto',
  Securities = 'securities',
  Robo = 'robo',
}

/**
 * InvestmentExperienceLevelEnum
 * List of investment experience levels
 */
export enum InvestmentExperienceLevelEnum {
  Limited = 'Limited',
  Average = 'Average',
  Extensive = 'Extensive',
}

/**
 * InvestmentHistoricalValue
 * Investment Historical values split up into different time frames consisting of InvestmentHistoricalValueData.
 */
export interface InvestmentHistoricalValue {
  /**
   * Past Day
   * Users total investment value over the past day
   * @default []
   */
  past_day?: InvestmentHistoricalValueDataPoint[];
  /**
   * Past Week
   * Users total investment value over the past week
   * @default []
   */
  past_week?: InvestmentHistoricalValueDataPoint[];
  /**
   * Past Month
   * Users total investment value over the past month
   * @default []
   */
  past_month?: InvestmentHistoricalValueDataPoint[];
  /**
   * Past Year
   * Users total investment value over the past year
   * @default []
   */
  past_year?: InvestmentHistoricalValueDataPoint[];
  /**
   * All Time
   * Users total investment value over all time
   * @default []
   */
  all_time?: InvestmentHistoricalValueDataPoint[];
}

/**
 * InvestmentHistoricalValueDataPoint
 * User investment value at a specific time in the past
 * NOTE: This is structured to match the data structure that the frontend charts are expecting
 */
export interface InvestmentHistoricalValueDataPoint {
  /**
   * Date
   * UTC Datetime of the datapoint
   * @format date-time
   */
  date: string;
  /**
   * Value
   * Users total investment value in USD at this time
   */
  value: string;
}

/**
 * InvestmentObjectiveEnum
 * List of investment objectives
 */
export enum InvestmentObjectiveEnum {
  Income = 'Income',
  CapitalPreservation = 'Capital Preservation',
  Growth = 'Growth',
  Speculation = 'Speculation',
}

/**
 * KYCApplicationTypeEnum
 * List of KYC application types
 */
export enum KYCApplicationTypeEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  ORG = 'ORG',
}

/** KYCFieldsResponse */
export interface KYCFieldsResponse {
  /** Kyc Status */
  kyc_status: string;
  /**
   * Have
   * @default []
   */
  have?: KycFieldResponse[];
  /**
   * Need
   * @default []
   */
  need?: KycFieldResponse[];
}

/**
 * KYCStatusEnum
 * List of KYC statuses
 */
export enum KYCStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  REJECTED = 'REJECTED',
  INCOMPLETE = 'INCOMPLETE',
  PENDING_USER = 'PENDING_USER',
  PENDING_AGREEMENT = 'PENDING_AGREEMENT',
  NOT_STARTED = 'NOT_STARTED',
  CANCELLED = 'CANCELLED',
  UNKNOWN = 'UNKNOWN',
  ACCOUNT_TAKEOVER = 'ACCOUNT_TAKEOVER',
}

/** KycFieldResponse */
export interface KycFieldResponse {
  /**
   * Required
   * Whether the field is required
   * @default true
   */
  required?: boolean;
  /**
   * The type of the field
   * @default "text"
   */
  value_type?: KycFieldValueTypes;
  /**
   * Options
   * Options for select fields
   */
  options?: string[];
  /**
   * Description
   * Description of the field
   * @default ""
   */
  description?: string;
  /**
   * Label
   * Display label for the field
   * @default ""
   */
  label?: string;
  /**
   * Pattern
   * Regex Validation pattern for the field
   */
  pattern?: string | null;
  /**
   * Name
   * The name of the field
   */
  name: string;
}

/**
 * KycFieldValueTypes
 * Enum of allowed KycFieldValueTypes
 */
export enum KycFieldValueTypes {
  Text = 'text',
  Select = 'select',
  Date = 'date',
  Ssn = 'ssn',
  Phone = 'phone',
  Email = 'email',
  PostalCode = 'postal_code',
  Int = 'int',
}

/**
 * KycSubmissionResult
 * Model for the KYC submission result
 */
export interface KycSubmissionResult {
  /** The KYC status of the user */
  kyc_status?: KYCStatusEnum | null;
  /**
   * Performed
   * Whether the KYC submission was performed
   * @default false
   */
  performed?: boolean;
  /**
   * Error Message
   * The error message if the KYC submission failed
   */
  error_message?: string | null;
}

/**
 * KycSubmissionResults
 * Model for the KYC submission results. Contains the results for crypto, securities, and robo KYC submissions.
 */
export interface KycSubmissionResults {
  /**
   * Model for the KYC submission result
   * @default {"performed":false}
   */
  crypto?: KycSubmissionResult;
  /**
   * Model for the KYC submission result
   * @default {"performed":false}
   */
  securities?: KycSubmissionResult;
  /**
   * Model for the KYC submission result
   * @default {"performed":false}
   */
  robo?: KycSubmissionResult;
}

/**
 * LabelUpdate
 * Update for a specific document label
 */
export interface LabelUpdate {
  /** Types of legal documents that can be uploaded */
  document_type: UploadDocumentTypeEnum;
  /**
   * Label
   * New label
   */
  label: string;
}

/**
 * LastEvaluatedKey
 * Represents the last item returned in results in order to fetch next set
 */
export interface LastEvaluatedKey {
  /**
   * P Key
   *
   *     p_key value to pass in to retrieve the next set of items
   *
   */
  p_key: string;
  /**
   * S Key
   *
   *     s_key value to pass in to retrieve the next set of items
   *
   */
  s_key: string;
}

/**
 * LastEvaluatedKeyTradingPeriodOrder
 * Used in pagination of Orders by TradingPeriod.
 */
export interface LastEvaluatedKeyTradingPeriodOrder {
  /**
   * P Key
   *
   *     p_key value to pass in to retrieve the next set of items
   *
   */
  p_key: string;
  /**
   * S Key
   *
   *     s_key value to pass in to retrieve the next set of items
   *
   */
  s_key: string;
  /**
   * Trading Period Id
   *
   *     trading_period.composite_key() value to pass in to retrieve the next set of orders.
   *
   */
  trading_period_id: string;
}

/**
 * LiquidationResponse
 * This class represents the response data that we collect from the
 * LiquidityProvider as we liquidate a user's holdings; an action
 * triggered via the Account Takeover process (user.handle_account_takeover()).
 */
export interface LiquidationResponse {
  /**
   * Token Responses
   * The liquidation responses for each token that was liquidated
   */
  token_responses: Record<string, LiquidationTokenResponse>;
}

/**
 * LiquidationTokenResponse
 * Nested model to represent the LP + Custodial response data for a single Token liquidation.
 */
export interface LiquidationTokenResponse {
  /** The liquidation Order response */
  liquidation_order: Order;
  /**
   * Liquidation Order Response
   * The liquidation order API response from the LP.
   */
  liquidation_order_response: object;
  /**
   * Custodian Transfer
   * The custody transfer response (from the User's account to our Enterprise Account)
   */
  custodian_transfer: object;
}

/**
 * LiquidityProviderEnum
 * List of supported Liquidity Providers
 */
export enum LiquidityProviderEnum {
  DummyLP = 'DummyLP',
  SFOX = 'sFOX',
}

/**
 * LiquidityProviderOrder
 * Represents an order from a liquidity provider.
 * This model is necessary to store responses from liquidity providers
 * on the Order object because it is possible for a single CryptoFi Order
 * to be comprised of multiple trades (orders) on the liquidity provider side.
 * We need insight into these individual trades to properly reconcile with
 * partners.
 */
export interface LiquidityProviderOrder {
  /**
   * Lp Order Identifier
   * The ID of the order from the liquidity provider.
   */
  lp_order_identifier: string;
  /**
   * Lp Order Response
   * The response of the order from the LP in dict form.
   */
  lp_order_response: object;
  /**
   * Lp Order Tx Quantity
   * The quantity of the single fill from the LP.
   */
  lp_order_tx_quantity?: string | null;
}

/** LiquidityProviderReconciliationDetails */
export interface LiquidityProviderReconciliationDetails {
  /** Indicates if the order details match between our records and LP's records. */
  status?: ReconciliationStatusEnum | null;
  /**
   * Discrepancy Details
   * Details of any discrepancies found during reconciliation, detailing each field that has a discrepancy or describing the nature of the discrepancy.
   */
  discrepancy_details?: string | null;
}

/**
 * MacroTradingPeriodStatus
 * Enum of the various states that the "macro" TradingPeriod and SecuritiesTradingPeriod
 * models can be in. These states are READ-ONLY shorthand representations of the
 * parent TradingPeriod model based the status of the nested TradingPeriod objects within it.
 */
export enum MacroTradingPeriodStatus {
  Open = 'open',
  Pending = 'pending',
  FullySettled = 'fully-settled',
}

/**
 * MixpanelChartGrouping
 * Model for storing Mixpanel Chart Groupings for a Financial Institution
 */
export interface MixpanelChartGrouping {
  /**
   * Engagement
   * List of urls that should be included in the 'Engagement' Mixpanel Chart Grouping
   * @default []
   */
  engagement?: string[];
  /**
   * Utilization
   * List of urls that should be included in the 'Utilization' Mixpanel Chart Grouping
   * @default []
   */
  utilization?: string[];
  /**
   * Trading
   * List of urls that should be included in the 'Trading' Mixpanel Chart Grouping
   * @default []
   */
  trading?: string[];
}

/**
 * MixpanelChartGroupingRequest
 * FastAPI request model for the `save-mixpanel-chart-grouping` endpoint.
 */
export interface MixpanelChartGroupingRequest {
  /** Chart Grouping Attr Name */
  chart_grouping_attr_name: string;
  /** Chart Grouping Attr Value */
  chart_grouping_attr_value: string[];
}

/**
 * MonthEnum
 * Static enum of calendar months.
 */
export enum MonthEnum {
  Value1 = '1',
  Value2 = '2',
  Value3 = '3',
  Value4 = '4',
  Value5 = '5',
  Value6 = '6',
  Value7 = '7',
  Value8 = '8',
  Value9 = '9',
  Value10 = '10',
  Value11 = '11',
  Value12 = '12',
}

/**
 * NcrSamlAssertion
 * The SAML assertion from NCR
 */
export interface NcrSamlAssertion {
  /**
   * Samlresponse
   * The SAML assertion from NCR
   */
  SAMLResponse: string;
}

/**
 * OpenSearchQuerySortOrder
 * Defines options for sorting in OpenSearch queries.
 */
export enum OpenSearchQuerySortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

/**
 * OpsCenterRoleType
 * The 3 Ops Center Role types:
 *  - SUPER-ADMIN: Can do everything including adding and removing other Ops Center users.
 *  - ACCOUNTING: Can reconcile but cannot manager users.
 *  - VIEW-ONLY: Can view everything but cannot reconcile or manage users.
 */
export enum OpsCenterRoleType {
  SUPERADMIN = 'SUPER-ADMIN',
  ACCOUNTING = 'ACCOUNTING',
  VIEWONLY = 'VIEW-ONLY',
}

/** Order */
export interface Order {
  /**
   * Xfr Err
   *
   * Determines if there was an error during the transfer on the banking core.
   * 0 for successful transfers. -1 for failed transfers.
   */
  xfr_err?: string | null;
  /**
   * Xfr Err Code
   * Error code thrown from the banking core for failed transfers.
   */
  xfr_err_code?: string | null;
  /**
   * Xfr Err Desc
   * Error description from the banking core for failed transfers.
   */
  xfr_err_desc?: string | null;
  /**
   * Xfr Id
   *
   * Primary key created in the online banking environment to identify the transfer
   * from the user's account to the FI's account and vice versa for sales.
   */
  xfr_id?: string | null;
  /**
   * Xfr Time
   * The time that the transfer was made in the banking core, in EPOCH time.
   */
  xfr_time?: string | null;
  /**
   * Xfr Amt
   * The total amount that was transferred in banking core.
   */
  xfr_amt?: string | null;
  /**
   * Xfr Rollback Time
   * The time that a rollback transaction was made from the GL
   * to the members account during an unsuccessful BUY order.
   */
  xfr_rollback_time?: string | null;
  /**
   * Xfr Rollback Id
   * The id of a rollback transaction from the GL to the
   * member's account during an unsuccessful BUY order.
   */
  xfr_rollback_id?: string | null;
  /**
   * Xfr Rollback Amount
   * The amount of a rollback transaction from the GL to the
   * member's account during an unsuccessful BUY order.
   */
  xfr_rollback_amount?: string | null;
  /**
   * Xfr Rollback Err Desc
   *
   * Determines if there was an error during the rollback transfer on the banking core.
   * 0 for successful transfers. -1 for failed transfers.
   */
  xfr_rollback_err_desc?: string | null;
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account Id
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  /**
   * Transact Time
   * GMT based time of order
   * @format date-time
   */
  transact_time?: string;
  /**
   * Trading Period Id
   * The trading period ID for the order.
   */
  trading_period_id?: string | null;
  /**
   * Tx Id
   * Transaction ID assigned by CWS to this order
   */
  tx_id?: string | null;
  /**
   * Client Order Id
   *
   * CryptoFi's computer order ID that is placed as part of the order.  It is composed of multiple fields.
   * This ID will be logged by the LP when placing the order and the components of the field can be used to identify
   * the FI, user and corresponding ledger record in CryptoFi's system.
   * The order ID is composed as follows:
   * FI<fi_id>-<user_account_id>-<ledger_record_id>
   * This field will be key in reconciling the LP order history with the CryptoFi
   * ledger as it can link the accounts.
   */
  client_order_id?: string | null;
  /**
   * User Sub Acct Id
   *
   * The FI specific ID of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  user_sub_acct_id?: string | null;
  /**
   * User Sub Acct Type
   *
   * The FI specific Name of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  user_sub_acct_type?: string | null;
  /**
   * Asset type of the order
   * @default "CRYPTO"
   */
  asset_type?: OrderAssetTypeEnum;
  /**
   *
   * Market symbol of coin exchanged in this transaction.
   */
  token?: TokenEnum | null;
  /**
   * Token Price
   *
   * Price of the individual coin at the time of the order and indicates how much the coin cost at
   * the time to buy or sell.  The figure will reflect the price based on the user's currency
   * such as USD, EUR, GBP.  In theory, the price can also be related to the price of other coins
   * such as BTC, however, CryptoFi is not planning to buy or sell using other cryptocurrencies at this time.
   */
  token_price?: string | null;
  /**
   * Securities Ticker
   * Ticker symbol of the security exchanged in this transaction.
   */
  securities_ticker?: string | null;
  /**
   * Securities Identifier
   * Unique identifier used to identify the security in the LP's system (i.e. CUSIP). This is needed as the ticker symbol can be reused by different securities.
   */
  securities_identifier?: string | null;
  /**
   * Securities Price
   * Price of the individual security at the time of the order and indicates how much the security cost at the time to buy or sell.
   */
  securities_price?: string | null;
  /** The type of security exchanged in this transaction. */
  securities_type?: SecuritiesTypeEnum | null;
  /**
   *
   * Trade side of the transaction such as BUY or SELL.
   */
  order_side: OrderSideEnum;
  /**
   *
   * Type of order.  MARKET indicates buy or sell at the current market price.
   * RFQ indicates that a quote was used to buy or sell for a total price.
   * @default "MARKET"
   */
  order_type?: OrderTypeEnum;
  /**
   *
   * The source of the order.  SELF_DIRECTED indicates the user placed the order themselves.
   * ROBO_ORDER indicates the order was placed by the system on behalf of the user via a deposit or withdrawal.
   * ROBO_REBALANCE indicates the order was placed as part of a rebalancing operation.
   * @default "SELF_DIRECTED"
   */
  order_source?: OrderSourceEnum;
  /**
   * Robo Transaction Id
   *
   * The ID of the robo transaction that generated the order.  This field will be used to link the order back to
   * the robo transaction. This id will be a concatenation of the p and s keys of the robo transaction, so that it can be
   * used to query the robo transaction table.
   */
  robo_transaction_id?: string | null;
  /**
   * Currency
   *
   * Currency used in transaction to either purchase the coin or receive as payment in sale.
   */
  currency: string;
  /**
   * Tx Quantity
   *
   * Amount of coin or equity actually transacted in either a buy or sell order.
   */
  tx_quantity?: string | null;
  /**
   * Tx Net
   *
   * When buying is the net amount paid to LP.  When selling is the net amount due to the user.
   */
  tx_net?: string | null;
  /**
   * Tx Gross
   *
   * When buying is the total amount the user is paying.  When selling, it is the total amount
   * of the transaction such as paid by the LP.
   */
  tx_gross?: string | null;
  /**
   * Tx Fee
   *
   * Total fee due to CryptoFi as part of the transaction.
   */
  tx_fee?: string | null;
  /**
   * Tx Fee Securities
   * Sell side securities transactions have a fee from the SEC associated with them. We track this separately as the tx_fee is an internal fee kept by investifi.
   */
  tx_fee_securities?: string | null;
  /**
   * Tx Fee Rec
   *
   * Shows the fee structure used in fee calculation.
   */
  tx_fee_rec?: string | null;
  /**
   * Order Price
   *
   * When specified, indicates the order was placed using a specific value to buy or sell.
   * When buying this value indicates the maximum amount of money the user would spend.
   * When selling this indicates the value the user would like to sell.
   */
  order_price?: string | null;
  /**
   *
   * The overall status of the transaction.  Will either the OK or ERROR to indicate if the transaction took place.
   * If successful and order executed then OK.  If the order did not execute then ERROR.
   * When calculating user balances, this field will be used to determine if the record should be counted or not.
   * The status will initialize to not be set when the record is first created and before the trade is attempted.
   */
  status: OrderStatusEnum;
  /**
   * Status Reason
   *
   * If the transaction were to fail for any reason this field will contain details indicating the issue.
   */
  status_reason?: string | null;
  /**
   * Liquidity Provider
   *
   * Name of Liquidity Provider or Securities Provider used to make the trade.
   */
  liquidity_provider?: string | null;
  /**
   * Liquidity Provider Trade Id
   *
   * The ID of the trade/order in the Liquidity Provider's or Securities Provider's system
   */
  liquidity_provider_trade_id?: string | null;
  /**
   * Liquidity Provider Account Id
   * The liquidity provider (e.g. RQD) account id, to which the Order will be placed.
   */
  liquidity_provider_account_id?: string | null;
  /**
   * Lp Order Request
   * The order request sent from us to the liquidity provider
   */
  lp_order_request?: string | null;
  /**
   * Lp Order Response
   *
   *     The response of the order from the LP or SP in json form.
   */
  lp_order_response?: string | null;
  /**
   * All Lp Order Responses
   *
   *     All responses from the LP or SP if multiple received.
   * @default []
   */
  all_lp_order_responses?: string[] | null;
  /**
   * Buy Order Funded
   *
   *         A flag indicating the transfer into the user's linked custodial account has been completed for a BUY order.
   * @default false
   */
  buy_order_funded?: boolean | null;
  /**
   * Staff Notes
   * Notes added by staff members.
   */
  staff_notes?: string | null;
  /**
   * Quoted Net Quantity
   * The net crypto quantity quoted on the FE for BUY orders.
   */
  quoted_net_quantity?: string | null;
  /**
   * Quoted Net Price
   * The net fiat quoted on the FE for SELL orders.
   */
  quoted_net_price?: string | null;
  /**
   * Custodial Transfer From Account
   * The account the funds were transferred from.
   */
  custodial_transfer_from_account?: string | null;
  /**
   * Custodial Transfer To Account
   * The account the funds were transferred to.
   */
  custodial_transfer_to_account?: string | null;
  /**
   * Custodial Transfer Fiat Amount
   * The amount of fiat to transfer.
   */
  custodial_transfer_fiat_amount?: string | null;
  /**
   * Liquidity Provider Orders
   * The liquidity provider orders associated with this order.
   * @default []
   */
  liquidity_provider_orders?: LiquidityProviderOrder[] | null;
  /** Object storing details related to the reconciliation of order details with the LP. */
  liquidity_provider_reconciliation_details?: LiquidityProviderReconciliationDetails;
  /**
   * Asset
   * Determines the name of the asset by dynamically looking at the token or securities_ticker
   * field based on the asset_type. This is useful for accessing the asset name in a generic way instead of
   * having to check the asset_type first.
   */
  asset: string;
  /**
   * Asset Price
   * Determines the price of the asset by dynamically looking at the token_price or securities_price
   * field based on the asset_type. This is useful for accessing the asset price in a generic way instead of
   * having to check the asset_type first.
   */
  asset_price: string | null;
  /**
   * Fee Amount
   * Determines the fee of the order. Crypto uses tx_net as it is an internal fee, securities use tx_fee_securities
   * which is a fee from the SEC so they must be tracked differently.
   *
   * Investifi does not charge internal fees for securities transactions.
   */
  fee_amount: string | null;
}

/**
 * OrderAssetTypeEnum
 * List of order asset types
 */
export enum OrderAssetTypeEnum {
  CRYPTO = 'CRYPTO',
  SECURITY = 'SECURITY',
}

/**
 * OrderCustomTransferModel
 * Custom model for transferring funds between accounts
 */
export interface OrderCustomTransferModel {
  /**
   * This indicates the direction that a transaction is going and is solely used in our admin
   * tooling to move funds between a Member's DDA and the FI General Ledger.
   */
  transaction_direction: TransactionDirectionEnum;
  /** Amount */
  amount: number | string;
  /** Transaction Memo */
  transaction_memo?: string | null;
}

/**
 * OrderEditModel
 * Validation of Order attributes for editing an Order in our staff tool.
 */
export interface OrderEditModel {
  /** Liquidity Provider Trade Id */
  liquidity_provider_trade_id: string;
  /** Trading Period Id */
  trading_period_id: string;
  /** Statuses an order can be in */
  status: OrderStatusEnum;
  /** Tx Gross */
  tx_gross: string;
  /** Staff Notes */
  staff_notes?: string | null;
}

/**
 * OrderPaginator
 * Holds list of orders and pagination fields
 */
export interface OrderPaginator {
  /** Items */
  items: Order[];
  last_evaluated_key?: LastEvaluatedKey | null;
  /**
   * Per Page
   * Maximum items to return / returned per page
   */
  per_page: number;
  /**
   * Has More
   *
   *     More items are available to be retrieved using the last_evaluated_key fields
   *
   * @default false
   */
  has_more?: boolean;
}

/**
 * OrderSideEnum
 * List of order side such as buy and sell.
 */
export enum OrderSideEnum {
  BUY = 'BUY',
  SELL = 'SELL',
}

/**
 * OrderSourceEnum
 * At Investifi we have multiple sources of orders. I.e. the user can
 * place an order themselves (SELF_DIRECTED), the user can deposit or
 * withdraw money into a robo account and then orders are placed for
 * them on their behalf based on what is in their portfolio (ROBO_ORDER),
 * or the user can have their portfolio rebalanced, in which case buy and sell
 * orders are placed for them in order to rebalance their portfolio. (ROBO_REBALANCE)
 */
export enum OrderSourceEnum {
  SELF_DIRECTED = 'SELF_DIRECTED',
  ROBO_ORDER = 'ROBO_ORDER',
  ROBO_REBALANCE = 'ROBO_REBALANCE',
}

/**
 * OrderStatusEnum
 * Statuses an order can be in
 */
export enum OrderStatusEnum {
  NONE = 'NONE',
  OK = 'OK',
  OK_ERROR = 'OK_ERROR',
  OK_FRAUD = 'OK_FRAUD',
  OK_LIQUIDATED = 'OK_LIQUIDATED',
  OK_PARTIAL = 'OK_PARTIAL',
  OK_BANK_TRANSFER = 'OK_BANK_TRANSFER',
  CANCELED = 'CANCELED',
  RECEIVED = 'RECEIVED',
  PARTIAL_FILL_BUY = 'PARTIAL_FILL_BUY',
  PARTIAL_FILL_SELL = 'PARTIAL_FILL_SELL',
  PENDING_ORDER = 'PENDING_ORDER',
  PENDING_SELL_SETTLEMENT = 'PENDING_SELL_SETTLEMENT',
  PENDING_BUY_SETTLEMENT_SELL_UNAVAILABLE = 'PENDING_BUY_SETTLEMENT_SELL_UNAVAILABLE',
  PENDING_BUY_SETTLEMENT = 'PENDING_BUY_SETTLEMENT',
  PENDING_SELL_SETTLEMENT_PARTIAL = 'PENDING_SELL_SETTLEMENT_PARTIAL',
  PENDING_BUY_SETTLEMENT_PARTIAL = 'PENDING_BUY_SETTLEMENT_PARTIAL',
  PENDING_XFR_SELL_SETTLED = 'PENDING_XFR_SELL_SETTLED',
  ERROR = 'ERROR',
  ERROR_BUY_RECONCILED = 'ERROR_BUY_RECONCILED',
  ERROR_BUY_RECONCILE_ERROR = 'ERROR_BUY_RECONCILE_ERROR',
  ERROR_BUY_OK_XFR = 'ERROR_BUY_OK_XFR',
  ERROR_BUY_ERROR_XFR = 'ERROR_BUY_ERROR_XFR',
  ERROR_SELL = 'ERROR_SELL',
  ERROR_XFR_OK_SELL = 'ERROR_XFR_OK_SELL',
}

/**
 * OrderSyncTxQuantityModel
 * Validation of Order attributes for syncing the tx_quantity with the LP.
 */
export interface OrderSyncTxQuantityModel {
  /** Tx Quantity */
  tx_quantity: number | string;
}

/**
 * OrderTypeEnum
 * Types of orders that can be placed with Liquidity Provider
 */
export enum OrderTypeEnum {
  MARKET = 'MARKET',
  RFQ = 'RFQ',
}

/**
 * OrderedEducationContentRequest
 * Order education content model.
 */
export interface OrderedEducationContentRequest {
  /**
   * Fi Id
   * Financial Institution ID.
   */
  fi_id?: string | null;
  /**
   * Sections
   * Ordered education content.
   */
  sections: (FinancialInstitutionEducationContentSection | DefaultEducationContentSection)[];
}

/**
 * PeriodicOrderDayOfWeek
 * Days of the week for weekly and biweekly orders.
 */
export enum PeriodicOrderDayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

/**
 * PeriodicOrderInterval
 * Intervals at which a periodic order should be executed.
 */
export enum PeriodicOrderInterval {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
}

/**
 * PeriodicOrderParity
 * Parity for biweekly orders.
 */
export enum PeriodicOrderParity {
  ODD = 'ODD',
  EVEN = 'EVEN',
}

/**
 * PeriodicOrderSetting
 * Contains the settings of recurring orders that will be processed at a set
 * interval and be executed on a best-effort basis.
 *
 * A recurring purchase of a security will have:
 *     product_type = InvestifiProductTypeEnum.SECURITIES
 *     asset_type = OrderAssetTypeEnum.SECURITY
 *
 * A recurring purchase of a cryptocurrency will have:
 *     product_type = InvestifiProductTypeEnum.CRYPTO
 *     asset_type = OrderAssetTypeEnum.CRYPTO
 *
 * A robo recurring deposit will have:
 *     product_type = InvestifiProductTypeEnum.ROBO
 *     asset_type = OrderAssetTypeEnum.SECURITY
 *     user_robo_account_id: str = Field(
 *         None,
 *         description="The identifier for the user's robo account.",
 *     )
 */
export interface PeriodicOrderSetting {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account Id
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  /** The interval at which the order should be executed. */
  interval: PeriodicOrderInterval;
  /** The day of the week for weekly and biweekly orders.Used for WEEKLY and BIWEEKLY intervals. */
  weekly_day_of_week?: PeriodicOrderDayOfWeek | null;
  /** The parity for biweekly orders. Used for BIWEEKLY intervals. */
  biweekly_parity?: PeriodicOrderParity | null;
  /**
   * Monthly Day Of Month
   * The day of the month for monthly orders. Used for MONTHLY intervals.
   */
  monthly_day_of_month?: number | null;
  /** The type of product (e.g. "crypto", "securities", "robo"). */
  product_type: InvestifiProductTypeEnum;
  /** The type of asset to purchase (e.g. "CRYPTO" or "SECURITY"). */
  asset_type: OrderAssetTypeEnum;
  /**
   * Asset Identifier
   * The identifier for the asset to purchase (e.g. "BTC" or "AAPL").
   */
  asset_identifier?: string | null;
  /**
   * Order Price
   * Total amount in fiat that the user has apportioned for the purchase
   */
  order_price: string;
  /**
   * User Sub Acct Id
   * Unique identifier for the user's sub-account(s) within the financial institution. Commonly used to identify sub-accounts or denote direct deposit accounts (ie. checking/savings account number).
   */
  user_sub_acct_id: string;
  /**
   * User Robo Account Id
   * The identifier for the user's robo account.
   */
  user_robo_account_id?: string;
  /**
   * Enabled
   * Whether or not the setting is enabled. Orders will not be queuedfor execution when this is False.
   * @default true
   */
  enabled?: boolean;
  /**
   * Deleted
   * Whether or not the setting has been deleted by the user
   * @default false
   */
  deleted?: boolean;
  /**
   * Most Recent Queued New Periodic Order Timestamp
   * The most recent time that a new periodic order was queued using this setting
   */
  most_recent_queued_new_periodic_order_timestamp?: string | null;
}

/**
 * PeriodicOrderSettingRequestBody
 * Request body for posting a new recurring order.
 */
export interface PeriodicOrderSettingRequestBody {
  /** Intervals at which a periodic order should be executed. */
  interval: PeriodicOrderInterval;
  /** Order Amount */
  order_amount: number | string;
  /** List of order asset types */
  asset_type: OrderAssetTypeEnum;
  /** List of InvestiFi product types used for KYC, terms acceptance, etc. */
  product_type: InvestifiProductTypeEnum;
  /** User Sub Acct Id */
  user_sub_acct_id: string;
  weekly_day_of_week?: PeriodicOrderDayOfWeek | null;
  biweekly_parity?: PeriodicOrderParity | null;
  /** Monthly Day Of Month */
  monthly_day_of_month?: number | null;
  /** Asset Identifier */
  asset_identifier?: string | null;
  /** User Robo Account Id */
  user_robo_account_id?: string;
  /**
   * Enabled
   * @default true
   */
  enabled?: boolean;
}

/**
 * PoliciesResponse
 * Response model for legal document policies
 */
export interface PoliciesResponse {
  terms_and_conditions?: UrlLink | null;
  privacy_policy?: UrlLink | null;
  authorization_agreement?: UrlLink | null;
  safe_service_agreement?: UrlLink | null;
}

/**
 * RTQQuestionUserResponse
 * A single response to the risk tolerance questionnaire.
 */
export interface RTQQuestionUserResponse {
  /**
   * Question Id
   * The question id
   */
  question_id: string;
  /**
   * Responses
   * The responses to the question
   */
  responses: string[];
}

/**
 * RTQUserResponse
 * The response to the risk tolerance questionnaire.
 */
export interface RTQUserResponse {
  /** Responses */
  responses?: RTQQuestionUserResponse[];
}

/** RebalanceRequestModel */
export interface RebalanceRequestModel {
  /** Fi Id */
  fi_id: string;
  /** User Account Id */
  user_account_id: string;
  /** Liquidity Provider Account Id */
  liquidity_provider_account_id: string;
}

/**
 * ReconciliationManager
 * Pydantic BaseModel that stores the reconciliation state for a Trading Period
 * during the reconciliation and diffing process.
 */
export interface ReconciliationManager {
  /**
   * (Macro) TradingPeriods associated to FinancialInstitutions.
   * Note: This model was initial built with Crypto only in mind. With the
   * introduction of Securities in 7/2024, the model was refactored to support
   * both Crypto and Securities TradingPeriods. We do this using nested
   * Pydantic models for both Crypto and Securities TradingPeriods.
   */
  trading_period: TradingPeriod;
}

/** ReconciliationStatusEnum */
export enum ReconciliationStatusEnum {
  OK = 'OK',
  ERROR = 'ERROR',
}

/**
 * RedisSecurityHistoricalPricing
 * Security Historical pricing split up into different time frames consisting of RedisSecurityPricingInfo.
 */
export interface RedisSecurityHistoricalPricing {
  /**
   * Past Week
   * Token price data over the past week
   * @default []
   */
  past_week?: RedisSecurityPricingInfo[];
  /**
   * Past Month
   * Token price data over the past month
   * @default []
   */
  past_month?: RedisSecurityPricingInfo[];
  /**
   * Past Year
   * Token price data over the past year
   * @default []
   */
  past_year?: RedisSecurityPricingInfo[];
  /**
   * All Time
   * Token price data
   * @default []
   */
  all_time?: RedisSecurityPricingInfo[];
}

/**
 * RedisSecurityImage
 * Stores a securities image url and associated metadata to assist the frontend in displaying the image correctly
 */
export interface RedisSecurityImage {
  /**
   * Url
   * URL to the security's logo
   */
  url?: string | null;
  /**
   * Is Light Image
   * Bool to tell the frontend whether or not the image is light so it can display it with the correct background color
   * @default false
   */
  is_light_image?: boolean;
}

/**
 * RedisSecurityInfo
 * A pydantic model for our security data stored in Redis. This model comes with the ability to also query redis similiar to how our
 * dyntastic models query dynamodb.
 */
export interface RedisSecurityInfo {
  /** Pk */
  pk?: string | null;
  /**
   * Id
   * Security ID from Intrinio
   */
  id?: string | null;
  /**
   * Name
   * The security's name
   */
  name?: string | null;
  /**
   * Code
   * The code representing which type of security this is. I.E. ETF, Stock etc
   */
  code?: string | null;
  /**
   * Securities Ticker
   * The ticker symbol for the security
   */
  securities_ticker?: string | null;
  /**
   * Securities Identifier
   * The composite figi (Financial Instrument Global Identifier) for the security
   */
  securities_identifier?: string | null;
  /**
   * Enabled
   * Whether the security is enabled for trading, this is populated from our daily data dump from intrinio. If this is null, it means we received a price for it but it is not enabled
   * @default false
   */
  enabled?: boolean;
  /** The risk profile of the security */
  risk_profile: RiskProfileEnum | null;
  /** The current price of the security, determined by the last price of a trade we received from Intrinio */
  current_price?: RedisSecurityPricingInfo | null;
  /** The cached data from the most recent complete trading day for the security i.e. yesterday's close price, volume, daily high, daily low etc. */
  last_trading_day_info?: RedisSecurityPricingLastTradingDayInfo | null;
  /** Timeseries data for a security showing the price at different intervals over time */
  historical_pricing?: RedisSecurityHistoricalPricing;
  /** The image associated with the security */
  image?: RedisSecurityImage | null;
}

/** RedisSecurityPricingInfo */
export interface RedisSecurityPricingInfo {
  /**
   * Value
   * The price at a given timestamp
   */
  value?: string | null;
  /**
   * Date
   * Timestamp for when the price was updated
   */
  date?: string | null;
}

/**
 * RedisSecurityPricingLastTradingDayInfo
 * Stores various metrics from the most recently completed trading day of a security
 * NOTE: Normally this will be data from yesterday, but if the security is no longer traded, it will be the last day it was traded
 *
 * NOTE: Keep RedisSecurityPricingLastTradingDayInfo synchronized in both these files:
 *     https://github.com/CryptoFi-LLC/cws-api/blob/main/api/lib/model/info.py
 *     https://github.com/CryptoFi-LLC/intrinio-bulk-data-download/blob/main/src/model.py
 */
export interface RedisSecurityPricingLastTradingDayInfo {
  /**
   * Close Price
   * The close price of the security
   */
  close_price?: string | null;
  /**
   * Volume
   * The volume of the security
   */
  volume?: number | null;
  /**
   * Daily High
   * The daily high of the security
   */
  daily_high?: string | null;
  /**
   * Daily Low
   * The daily low of the security
   */
  daily_low?: string | null;
  /**
   * Fifty Two Week High
   * The 52 week high of the security
   */
  fifty_two_week_high?: string | null;
  /**
   * Fifty Two Week Low
   * The 52 week low of the security
   */
  fifty_two_week_low?: string | null;
  /**
   * Date
   * Timestamp for the date this data applies too
   */
  date?: string | null;
}

/**
 * ReportProductType
 * All our Reports can either be CRYPTO, SECURITY or ROBO
 */
export enum ReportProductType {
  CRYPTO = 'CRYPTO',
  SECURITY = 'SECURITY',
}

/**
 * ReportType
 * Each report (whether its Crypto or Securities)
 * has a type, e.g. AccountStatement_v2, ConfirmStatement, Transactions
 */
export enum ReportType {
  AccountStatement = 'AccountStatement',
  TradeConfirmation = 'TradeConfirmation',
  Transactions = 'Transactions',
  TaxStatement = 'TaxStatement',
}

/**
 * RiskProfileEnum
 * List of risk profiles. While these values are the same as RiskToleranceEnum, the RiskToleranceEnum is
 * used in specific question on the kyc form while the RiskProfileEnum combines several factors to determine
 * which level of risk the user should be allowed to take.
 *
 * This enum will be used to mark both users and assets with a risk level and then be used to determine whether a user's
 * risk level is compatible with the risk level of an asset.
 *
 * NOTE: These are purposefully ints so that the comparison between user and asset risk levels is a simple math calculation.
 * NOTE: This enum purposefully does not have a 0 as it is a falsy value and can introduce bugs were not expected.
 */
export enum RiskProfileEnum {
  Value1 = -1,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

/**
 * RiskToleranceEnum
 * List of risk tolerances
 */
export enum RiskToleranceEnum {
  VeryConservative = 'Very Conservative',
  Conservative = 'Conservative',
  Moderate = 'Moderate',
  Aggressive = 'Aggressive',
}

/**
 * RoboDepositRequestBody
 * Request body for robo deposit orders.
 */
export interface RoboDepositRequestBody {
  /**
   * Currency
   * Currency used in transaction to either purchase the asset or receive as payment in sale.
   * @default "USD"
   */
  currency?: 'USD';
  /**
   * Account Number
   *
   *     The FI specific ID of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_number?: string | null;
  /**
   * Account Type
   *
   *     The FI specific Name of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_type?: string | null;
  /**
   * Deposit Amount
   * Amount of USD to deposit into the user's account.
   */
  deposit_amount: number | string;
  /**
   * Liquidity Provider Account Id
   * The liquidity provider (e.g. RQD) account id, to which the Orders for this deposit will be placed.
   */
  liquidity_provider_account_id: string;
}

/**
 * RoboPortfolio
 * A RoboPortfolio is a unique collection of RoboPortfolioAssetAllocations that make up the entire portfolio.
 *
 * The sum of the allocation_percentage of all the RoboPortfolioAssetAllocations must be 100.
 * The RoboPortfolioAssetAllocations in allocations must be unique in terms of ticker.
 */
export interface RoboPortfolio {
  /**
   * P Key
   * The partition key for the table
   * @default "RoboPortfolio"
   */
  p_key?: string;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Portfolio Id
   * The unique identifier for the RoboPortfolio
   */
  portfolio_id?: string;
  /**
   * Name
   * The human readable name of the RoboPortfolio
   * @default ""
   */
  name?: string;
  /**
   * Description
   * The RoboPortfolio description
   * @default ""
   */
  description?: string;
  /**
   * Fi Id
   * The financial institution id that this RoboPortfolio belongs to. If it is not filled out it is a global RoboPortfolio
   */
  fi_id?: string | null;
  /** The risk profile that a user must have to be able to use this RoboPortfolio */
  risk_profile: RiskProfileEnum;
  /**
   * Risk Score
   * The risk score of the RoboPortfolio which would further limit the users that can use this RoboPortfolio based on their risk score
   */
  risk_score?: number | null;
  /**
   * Allocations
   * The breakdown of the RoboPortfolio in terms of asset allocation
   */
  allocations: RoboPortfolioAssetAllocationOutput[];
}

/**
 * RoboPortfolioAssetAllocation
 * This model is an asset in a robo portfolio and the percentage allocation of the asset said portfolio.
 * Together with the other RoboPortfolioAssetAllocations, they make up a RoboPortfolio with a total weight of 100.
 */
export interface RoboPortfolioAssetAllocationInput {
  /**
   * Asset
   * The asset identifier - either a securities ticker or MMA account
   */
  asset: string;
  /**
   * Backup Asset
   * The optional backup asset identifier - either a ticker or MMA account; This is swapped in for the primary asset if the user has reasonable restrictions
   */
  backup_asset?: string | null;
  /**
   * Allocation Percentage
   * The asset's percentage allocation in the portfolio when balanced. e.g. 50.0 for 50%
   */
  allocation_percentage: number | string;
}

/**
 * RoboPortfolioAssetAllocation
 * This model is an asset in a robo portfolio and the percentage allocation of the asset said portfolio.
 * Together with the other RoboPortfolioAssetAllocations, they make up a RoboPortfolio with a total weight of 100.
 */
export interface RoboPortfolioAssetAllocationOutput {
  /**
   * Asset
   * The asset identifier - either a securities ticker or MMA account
   */
  asset: string;
  /**
   * Backup Asset
   * The optional backup asset identifier - either a ticker or MMA account; This is swapped in for the primary asset if the user has reasonable restrictions
   */
  backup_asset?: string | null;
  /**
   * Allocation Percentage
   * The asset's percentage allocation in the portfolio when balanced. e.g. 50.0 for 50%
   */
  allocation_percentage: string;
}

/**
 * RoboPortfolioRequestModel
 * Model for creating a new RoboPortfolio (Global or FI specific). This model
 * explicitly excludes attributes that will be configured during initialization.
 *
 * The `fi_id` attribute is an optional field that defaults to None (when creating a global portfolio).
 * However, it is available to be configured in Ops Center endpoints when RIAs at
 * an FI create a new portfolio for their FI.
 */
export interface RoboPortfolioRequestModel {
  /**
   * Fi Id
   * The ID of the financial institution
   */
  fi_id?: string | null;
  /**
   * Name
   * The name of the portfolio
   */
  name: string;
  /**
   * Description
   * The description of the portfolio
   * @default ""
   */
  description?: string | null;
  /** The risk profile of the portfolio */
  risk_profile: RiskProfileEnum;
  /**
   * Risk Score
   * The risk score of the portfolio which would further limit the users that can use this portfolio based on their risk score
   */
  risk_score: number;
  /**
   * Allocations
   * The breakdown of the portfolio in terms of asset allocation
   */
  allocations: RoboPortfolioAssetAllocationInput[];
}

/**
 * RoboRiskToleranceCandidateResponse
 * A single response to a robo risk tolerance question.
 */
export interface RoboRiskToleranceCandidateResponse {
  /**
   * Response
   * The response to the question
   */
  response: string;
  /**
   * Risk Value
   * The risk value of the response
   */
  risk_value: number;
}

/**
 * RoboRiskToleranceQuestion
 * A single question in the robo risk tolerance questionnaire.
 */
export interface RoboRiskToleranceQuestion {
  /**
   * Id
   * Unique identifier for the question
   */
  id?: string;
  /**
   * Question
   * The question text
   */
  question: string;
  /**
   * Responses
   * List of possible responses to the question
   */
  responses?: RoboRiskToleranceCandidateResponse[];
  /**
   * Allow Multiselect
   * Whether the question allows multiple responses
   * @default false
   */
  allow_multiselect?: boolean;
}

/**
 * RoboRiskToleranceQuestionnaire
 * Model for the robo risk tolerance questionnaire.
 */
export interface RoboRiskToleranceQuestionnaire {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * Questions
   * List of questions in the questionnaire
   */
  questions?: RoboRiskToleranceQuestion[];
}

/** RoboTransactionOpenSearch */
export interface RoboTransactionOpenSearch {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account ID
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  /**
   * Liquidity Provider Account Id
   * The liquidity provider account that facilitated the transaction.
   */
  liquidity_provider_account_id: string;
  /** The type of the transaction (DEPOSIT, WITHDRAWAL, REBALANCE). */
  robo_transaction_type: RoboTransactionTypeEnum;
  /**
   * Robo Transaction Amount
   * The amount of money involved in the transaction.
   */
  robo_transaction_amount?: string | null;
  /**
   * Account Number
   *
   *     The FI specific ID of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_number?: string | null;
  /**
   * Account Type
   *
   *     The FI specific Name of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_type?: string | null;
  /**
   * Liquidate All
   * Flag to indicate whether to liquidate all securities in the user's portfolio for a withdrawal.
   * @default false
   */
  liquidate_all?: boolean | null;
  /**
   * Tolerance
   * The tolerance for the rebalance.
   * @default "0.02"
   */
  tolerance?: string;
  /**
   * Transactions
   * The list of orders associated with this Robo Transaction.
   */
  transactions?: Transaction[];
}

/** RoboTransactionTypeEnum */
export enum RoboTransactionTypeEnum {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  REBALANCE = 'REBALANCE',
}

/**
 * RoboWithdrawalRequestBody
 * Request body for robo withdrawal orders.
 */
export interface RoboWithdrawalRequestBody {
  /**
   * Currency
   * Currency used in transaction to either purchase the asset or receive as payment in sale.
   * @default "USD"
   */
  currency?: 'USD';
  /**
   * Account Number
   *
   *     The FI specific ID of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_number?: string | null;
  /**
   * Account Type
   *
   *     The FI specific Name of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_type?: string | null;
  /**
   * Withdrawal Amount
   * Amount of USD to withdraw from the user's robo portfolio.
   */
  withdrawal_amount: number | string;
  /**
   * Liquidity Provider Account Id
   * The liquidity provider (e.g. RQD) account id, from which the Orders for this withdrawal will be placed.
   */
  liquidity_provider_account_id: string;
  /**
   * Liquidate All
   * Flag to indicate whether to liquidate all securities in the user's portfolio.
   * @default false
   */
  liquidate_all?: boolean | null;
}

/**
 * SearchOrderByIdRequest
 * FastAPI request model for the `order-search-by-tx-id` endpoint.
 */
export interface SearchOrderByIdRequest {
  /** Tx Id */
  tx_id: string;
}

/**
 * SearchRoboTransactionPaginator
 * Holds list of Robo Transactions and pagination fields from OpenSearch queries
 */
export interface SearchRoboTransactionPaginator {
  /**
   * Items
   * @default []
   */
  items?: RoboTransactionOpenSearch[];
  /**
   * Total Records
   *
   *         Total records found in search capped to 10,000
   *
   * @default 0
   */
  total_records?: number;
  /**
   * Last Record Number
   *
   *     The last record number returned in the results. If the results have more records
   *     then include last_record_number in subsequent requests to get the remaining records
   *
   */
  last_record_number: number;
  /**
   * Per Page
   * Maximum items to return / returned per page
   */
  per_page: number;
  /**
   * Has More
   *
   *     More items are available to be retrieved using the last_record_number field
   *
   * @default false
   */
  has_more?: boolean;
}

/**
 * SearchTransactionPaginator
 * Holds list of Transaction and pagination fields from OpenSearch queries
 */
export interface SearchTransactionPaginator {
  /**
   * Items
   * @default []
   */
  items?: Transaction[];
  /**
   * Total Records
   *
   *         Total records found in search capped to 10,000
   *
   * @default 0
   */
  total_records?: number;
  /**
   * Last Record Number
   *
   *     The last record number returned in the results. If the results have more records
   *     then include last_record_number in subsequent requests to get the remaining records
   *
   */
  last_record_number: number;
  /**
   * Per Page
   * Maximum items to return / returned per page
   */
  per_page: number;
  /**
   * Has More
   *
   *     More items are available to be retrieved using the last_record_number field
   *
   * @default false
   */
  has_more?: boolean;
}

/**
 * SearchUserPaginator
 * Holds list of users and pagination fields from OpenSearch queries
 */
export interface SearchUserPaginator {
  /**
   * Items
   * @default []
   */
  items?: UserSearchModel[] | null;
  /**
   * Total Records
   *
   *         Total records found in search capped to 10,000
   *
   * @default 0
   */
  total_records?: number;
  /**
   * Last Record Number
   *
   *     The last record number returned in the results. If the results have more records
   *     then include last_record_number in subsequent requests to get the remaining records
   *
   */
  last_record_number: number;
  /**
   * Per Page
   * Maximum items to return / returned per page
   */
  per_page: number;
  /**
   * Has More
   *
   *     More items are available to be retrieved using the last_record_number field
   *
   * @default false
   */
  has_more?: boolean;
}

/**
 * SecuritiesBuyOrderRequestBody
 * Represents the request body used in placing BUY orders via API for securities.
 */
export interface SecuritiesBuyOrderRequestBody {
  /**
   * Order Side
   * Trade side of the transaction such as BUY or SELL
   * @default "BUY"
   */
  order_side?: 'BUY';
  /**
   * Order Price
   *
   *         Total price in FIAT the user has apportioned to be placed in the purchase.
   *         The intent is for this amount to be fully used in purchasing the maximum
   *         amount of the token that can be had at this price.
   */
  order_price: number | string;
  /**
   * Net Order Quantity
   * The net asset quantity quoted on the FE for BUY orders.
   */
  net_order_quantity?: string | null;
  /**
   * List of order asset types
   * @default "SECURITY"
   */
  asset_type?: OrderAssetTypeEnum;
  /**
   * Currency
   * Currency used in transaction to either purchase the asset or receive as payment in sale.
   * @default "USD"
   */
  currency?: 'USD';
  /**
   * Account Number
   *
   *     The FI specific ID of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_number?: string | null;
  /**
   * Account Type
   *
   *     The FI specific Name of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_type?: string | null;
  /**
   * Securities Ticker
   * Market symbol/ticker (AAPL) of the security exchanged in this transaction.
   */
  securities_ticker: string;
  /**
   * Securities Identifier
   * Unique identifier used to identify the security in the LP's system (i.e. CUSIP). This is needed as the ticker symbol can be reused by different securities.
   */
  securities_identifier: string;
  /** Type of security being traded (i.e. EQUITY, ETF, OPTIONS etc.). */
  securities_type: SecuritiesTypeEnum;
  /** The source of the order, i.e. SELF_DIRECTED, ROBO_ORDER, ROBO_REBALANCE. */
  order_source?: OrderSourceEnum | null;
  /**
   * Liquidity Provider Account Id
   * The liquidity provider (e.g. RQD) account id, to which the Order will be placed.
   */
  liquidity_provider_account_id?: string | null;
}

/**
 * SecuritiesProviderEnum
 * List of supported Security Providers
 */
export enum SecuritiesProviderEnum {
  DummySecurity = 'DummySecurity',
  RQD = 'RQD',
}

/**
 * SecuritiesSellOrderRequestBody
 * Represents the request body used in placing SELL orders via API for securities.
 */
export interface SecuritiesSellOrderRequestBody {
  /**
   * Order Side
   * Trade side of the transaction such as BUY or SELL
   * @default "SELL"
   */
  order_side?: 'SELL';
  /**
   * Order Quantity
   * Amount of asset to sell.
   */
  order_quantity: number | string;
  /**
   * Net Order Price
   * The net fiat quantity quoted on the FE for SELL orders.
   */
  net_order_price?: string | null;
  /**
   * List of order asset types
   * @default "SECURITY"
   */
  asset_type?: OrderAssetTypeEnum;
  /**
   * Currency
   * Currency used in transaction to either purchase the asset or receive as payment in sale.
   * @default "USD"
   */
  currency?: 'USD';
  /**
   * Account Number
   *
   *     The FI specific ID of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_number?: string | null;
  /**
   * Account Type
   *
   *     The FI specific Name of the user's account used to fund the sale or to receive the proceeds of the sale.
   */
  account_type?: string | null;
  /**
   * Securities Ticker
   * Market symbol/ticker (AAPL) of the security exchanged in this transaction.
   */
  securities_ticker: string;
  /**
   * Securities Identifier
   * Unique identifier used to identify the security in the LP's system (i.e. CUSIP). This is needed as the ticker symbol can be reused by different securities.
   */
  securities_identifier: string;
  /** Type of security being traded (i.e. EQUITY, ETF, OPTIONS etc.). */
  securities_type: SecuritiesTypeEnum;
  /** The source of the order, i.e. SELF_DIRECTED, ROBO_ORDER, ROBO_REBALANCE. */
  order_source?: OrderSourceEnum | null;
  /**
   * Liquidity Provider Account Id
   * The liquidity provider (e.g. RQD) account id, to which the Order will be placed.
   */
  liquidity_provider_account_id?: string | null;
}

/**
 * SecuritiesTradingPeriod
 * Securities TradingPeriods nested within a TradingPeriod.
 */
export interface SecuritiesTradingPeriod {
  /**
   * Securities Sell Side TradingPeriod sub-model. This Pydantic model
   * is used to represent the sell side of the Securities TradingPeriod and
   * is nested within the SecuritiesTradingPeriod model, which itself
   * is nested within the TradingPeriod model.
   * @default {"status":"open","total_transaction_volume":"0","total_transaction_fees":"0","received_sell_proceeds_ach_push":false,"received_dividend_proceeds_ach_push":false}
   */
  sell_side?: SellSideSecuritiesTradingPeriod;
  /**
   * Securities Buy Side TradingPeriod sub-model. This Pydantic model
   * is used to represent the buy side of the Securities TradingPeriod and
   * is nested within the SecuritiesTradingPeriod model, which itself
   * is nested within the TradingPeriod model.
   * @default {"status":"open","total_transaction_volume":"0","total_transaction_fees":"0"}
   */
  buy_side?: BuySideSecuritiesTradingPeriod;
  /**
   * Total Transaction Volume
   * Get the total transaction volume for the nested Buy/Sell Securities TradingPeriods.
   */
  total_transaction_volume: string;
  /**
   * Total Transaction Fees
   * Get the total transaction fees for the nested Securities TradingPeriod.
   */
  total_transaction_fees: string;
}

/**
 * SecuritiesTypeEnum
 * List of supported Securities Types
 */
export enum SecuritiesTypeEnum {
  EQUITY = 'EQUITY',
  ETF = 'ETF',
}

/**
 * SellSideSecuritiesTradingPeriod
 * Securities Sell Side TradingPeriod sub-model. This Pydantic model
 * is used to represent the sell side of the Securities TradingPeriod and
 * is nested within the SecuritiesTradingPeriod model, which itself
 * is nested within the TradingPeriod model.
 */
export interface SellSideSecuritiesTradingPeriod {
  /** @default "open" */
  status?: LibModelReconciliationSellSideSecuritiesTradingPeriodStateMachineStates;
  /**
   * Total Transaction Volume
   * @default "0"
   */
  total_transaction_volume?: string;
  /**
   * Total Transaction Fees
   * @default "0"
   */
  total_transaction_fees?: string;
  /**
   * Received Sell Proceeds Ach Push
   * @default false
   */
  received_sell_proceeds_ach_push?: boolean;
  /**
   * Received Dividend Proceeds Ach Push
   * @default false
   */
  received_dividend_proceeds_ach_push?: boolean;
}

/** StateEnum */
export enum StateEnum {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

/**
 * StatusResponse
 * Status of a microservice returned to the frontend to influence UI functionality
 */
export interface StatusResponse {
  /** Enum of microservice statuses returned to our various frontends in order to dynamically turn on/off functionality */
  status: SystemStatuses;
  /** Message */
  message?: string | null;
  /** Start Time Utc */
  start_time_utc?: string | null;
  /** End Time Utc */
  end_time_utc?: string | null;
}

/** SymXchangeEnv */
export enum SymXchangeEnv {
  LOCAL = 'LOCAL',
  VIP_SANDBOX = 'VIP_SANDBOX',
  PRE_PROD = 'PRE_PROD',
  PROD = 'PROD',
}

/**
 * SystemStatuses
 * Enum of microservice statuses returned to our various frontends in order to dynamically turn on/off functionality
 */
export enum SystemStatuses {
  OK = 'OK',
  WARNING = 'WARNING',
  DOWN = 'DOWN',
}

/**
 * TelemetryClientSideEventsEnum
 * The predefined set of Front-End client-side events that we want to track in Mixpanel.
 */
export enum TelemetryClientSideEventsEnum {
  ClickedMainNavCryptoHomeClient = 'Clicked Main Nav: Crypto Home [client]',
  ClickedMainNavPortfolioClient = 'Clicked Main Nav: Portfolio [client]',
  ClickedMainNavTransactionHistoryClient = 'Clicked Main Nav: Transaction History [client]',
  ClickedMainNavLearnClient = 'Clicked Main Nav: Learn [client]',
  ClickedCryptoHomeInvestButtonClient = 'Clicked Crypto Home: Invest Button [client]',
  ClickedCryptoHomeLearnButtonClient = 'Clicked Crypto Home: Learn Button [client]',
  ClickedCryptoHomeAssetButtonClient = 'Clicked Crypto Home: Asset Button [client]',
  ClickedCryptoHomeNoCryptoHowToBuyAndSellCryptoButtonClient = 'Clicked Crypto Home No Crypto: How To Buy And Sell Crypto Button [client]',
  ClickedCryptoHomeNoCryptoCrypto101ButtonClient = 'Clicked Crypto Home No Crypto: Crypto 101 Button [client]',
  ClickedPortfolioTradeButtonClient = 'Clicked Portfolio: Trade Button [client]',
  ClickedPortfolioCompleteProfileButtonClient = 'Clicked Portfolio: Complete Profile Button [client]',
  ClickedTXHistoryAppliedFiltersClient = 'Clicked TX History: Applied Filters [client]',
  ClickedLearnArticleClient = 'Clicked Learn: Article [client]',
  ClickedAssetBuyButtonClient = 'Clicked Asset: Buy Button [client]',
  ClickedAssetSellButtonClient = 'Clicked Asset: Sell Button [client]',
  ClickedAssetPreviewBuyButtonClient = 'Clicked Asset: Preview Buy Button [client]',
  ClickedAssetPreviewSellButtonClient = 'Clicked Asset: Preview Sell Button [client]',
  ClickedAssetConfirmBuyButtonClient = 'Clicked Asset: Confirm Buy Button [client]',
  ClickedAssetConfirmSellButtonClient = 'Clicked Asset: Confirm Sell Button [client]',
  ClickedAssetDoneButtonClient = 'Clicked Asset: Done Button [client]',
  ViewedAssetPageClient = 'Viewed Asset Page [client]',
  ViewedOrderReceivedScreenClient = 'Viewed Order Received Screen [client]',
  OnboardingModalViewedWelcomeScreenClient = 'Onboarding Modal: Viewed Welcome Screen [client]',
  OnboardingModalClickedNextButtonOnWelcomeScreenClient = 'Onboarding Modal: Clicked Next Button on Welcome Screen [client]',
  OnboardingModalClickedNextButtonOnQuickAndEasyScreenClient = 'Onboarding Modal: Clicked Next Button on Quick and Easy Screen [client]',
  OnboardingModalClickedNoThanksLetsTradeButtonOnLearnMoreScreenClient = 'Onboarding Modal: Clicked No Thanks Lets Trade Button on Learn More Screen [client]',
  OnboardingModalClickedWhatIsCryptocurrencyButtonOnLearnMoreScreenClient = 'Onboarding Modal: Clicked What is Cryptocurrency Button on Learn More Screen [client]',
  OnboardingModalClickedWhyAreBlockchainsSafeButtonOnLearnMoreScreenClient = 'Onboarding Modal: Clicked Why Are Blockchains Safe Button on Learn More Screen [client]',
  OnboardingModalClickedSeeAllLearningTopicsButtonOnLearnMoreScreenClient = 'Onboarding Modal: Clicked See All Learning Topics Button on Learn More Screen [client]',
  OnboardingModalAbandonedOnboardingFlowClient = 'Onboarding Modal: Abandoned Onboarding Flow [client]',
  TCModalClickedReviewCryptoTermsAndConditionsButtonClient = 'T&C Modal: Clicked Review Crypto Terms And Conditions Button [client]',
  TCModalClickedSkipForNowButtonClient = 'T&C Modal: Clicked Skip for Now Button [client]',
  TCModalClickedNotSubjectToBackupWithholdingCheckboxClient = 'T&C Modal: Clicked Not Subject to Backup Withholding Checkbox [client]',
  TCModalClickedEtanasESignConsentAndPrivacyPolicyCheckboxClient = "T&C Modal: Clicked Etana's E-Sign Consent and Privacy Policy Checkbox [client]",
  TCModalClickedCryptoFisESignConsentAndPrivacyPolicyCheckboxClient = "T&C Modal: Clicked CryptoFi's E-Sign Consent and Privacy Policy Checkbox [client]",
  TCModalClickedAgreeToTermsAndConditionsCheckboxClient = 'T&C Modal: Clicked Agree to Terms and Conditions Checkbox [client]',
  TCModalClickedAgreeToCustodianTermsAndConditionsCheckboxClient = 'T&C Modal: Clicked Agree to Custodian Terms and Conditions Checkbox [client]',
  TCModalViewedTCFormClient = 'T&C Modal: Viewed T&C Form [client]',
  TCModalViewedPrivacyPolicyFormClient = 'T&C Modal: Viewed Privacy Policy Form [client]',
  TCModalViewedCustodianTCFormClient = 'T&C Modal: Viewed Custodian T&C Form [client]',
  TCModalViewedCustodianPrivacyPolicyFormClient = 'T&C Modal: Viewed Custodian Privacy Policy Form [client]',
  KYCModalClickedConfirmAndSubmitButtonClient = 'KYC Modal: Clicked Confirm And Submit Button [client]',
  KYCModalViewedKYCFormClient = 'KYC Modal: Viewed KYC Form [client]',
  KYCModalEnteredEmploymentStatusInKYCFormClient = 'KYC Modal: Entered Employment Status in KYC Form [client]',
  KYCModalEnteredIncomeVerificationInKYCFormClient = 'KYC Modal: Entered Income Verification in KYC Form [client]',
  KYCModalEnteredNetWorthInKYCFormClient = 'KYC Modal: Entered Net Worth in KYC Form [client]',
  KYCModalEnteredEmailInKYCFormClient = 'KYC Modal: Entered Email in KYC Form [client]',
  KYCModalEnteredCountryInKYCFormClient = 'KYC Modal: Entered Country in KYC Form [client]',
  KYCModalClosedKYCFormClient = 'KYC Modal: Closed KYC Form [client]',
  OTPStepClosedModalClient = 'OTP Step: Closed Modal [client]',
  OTPStepClickedYoureAllSetButtonClient = "OTP Step: Clicked You're All Set Button [client]",
}

/**
 * TermsAndConditionTypes
 * List of supported Terms and Conditions Types
 */
export enum TermsAndConditionTypes {
  Crypto = 'crypto',
  Securities = 'securities',
  Investifi = 'investifi',
  InvestifiBrokerageLicense = 'investifi_brokerage_license',
  AdvisifiBrokerageLicense = 'advisifi_brokerage_license',
}

/**
 * TermsAndConditionsUploadResponse
 * Response model for Terms & Conditions uploads
 */
export interface TermsAndConditionsUploadResponse {
  /**
   * Upload Date
   * @format date-time
   */
  upload_date: string;
  /** Label */
  label: string;
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string;
  /** S3 Previous Version Id */
  s3_previous_version_id?: string | null;
}

/** TermsProviderUploadTypeEnum */
export enum TermsProviderUploadTypeEnum {
  CMA = 'CMA',
  RIA = 'RIA',
  RQD = 'RQD',
  DummySecurity = 'DummySecurity',
  SFOX = 'sFOX',
  DummyCustodian = 'DummyCustodian',
}

/**
 * TermsUpdateRequest
 * Request to update terms and conditions text
 */
export interface TermsUpdateRequest {
  /**
   * Description
   * Main description shown under terms and conditions checkbox
   */
  description?: string | null;
  /**
   * Labels
   * Updates to document labels
   */
  labels?: LabelUpdate[] | null;
}

/**
 * TermsUpdateResponse
 * Request for an update to terms and conditions text
 */
export interface TermsUpdateResponse {
  /** Message */
  message: string;
  /** Updated Fields */
  updated_fields: object;
}

/**
 * TermsVersionUpdateResponse
 * Response model for terms version update
 */
export interface TermsVersionUpdateResponse {
  /**
   * Message
   * Status message indicating success
   */
  message: string;
  /**
   * New Version
   * The new version number after increment
   */
  new_version: string;
  /**
   * Previous Version
   * The version number before increment
   */
  previous_version: string;
  /**
   * Date Updated
   * Timestamp when version was updated
   * @format date-time
   */
  date_updated: string;
}

/**
 * TokenEnum
 * List of supported Cryptocurrency Tokens
 */
export enum TokenEnum {
  BTC = 'BTC',
  BCH = 'BCH',
  ETH = 'ETH',
  LTC = 'LTC',
  XLM = 'XLM',
  XRP = 'XRP',
  LINK = 'LINK',
  AVAX = 'AVAX',
  ALGO = 'ALGO',
  MKR = 'MKR',
  SOL = 'SOL',
  DOGE = 'DOGE',
}

/**
 * TokenHistoricalPriceData
 * Token price data for a specific point in time.
 * NOTE: This is structured to match the data structure that the frontend charts are expecting
 */
export interface TokenHistoricalPriceData {
  /**
   * Date
   * UTC Datetime of the datapoint
   * @format date-time
   */
  date: string;
  /**
   * Value
   * Tokens USD Price at this time
   */
  value: string;
}

/**
 * TokenHistoricalPriceV2
 * Token Historical pricing on a per token basis.
 */
export interface TokenHistoricalPriceV2 {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Fi Id
   * @default "COMMON"
   */
  fi_id?: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /** Token this data represents, ie. BTC, ETH */
  token: TokenEnum;
  /** Tokens historical pricing */
  historical_pricing?: TokenHistoricalPricing;
}

/**
 * TokenHistoricalPricing
 * Token Historical pricing split up into different time frames consisting of TokenHistoricalPriceData.
 */
export interface TokenHistoricalPricing {
  /**
   * Past Hour
   * Token price data over the past hour
   * @default []
   */
  past_hour?: TokenHistoricalPriceData[];
  /**
   * Past Day
   * Token price data over the past day
   * @default []
   */
  past_day?: TokenHistoricalPriceData[];
  /**
   * Past Week
   * Token price data over the past week
   * @default []
   */
  past_week?: TokenHistoricalPriceData[];
  /**
   * Past Month
   * Token price data over the past month
   * @default []
   */
  past_month?: TokenHistoricalPriceData[];
  /**
   * Past Year
   * Token price data over the past year
   * @default []
   */
  past_year?: TokenHistoricalPriceData[];
  /**
   * All Time
   * Token price data
   * @default []
   */
  all_time?: TokenHistoricalPriceData[];
}

/**
 * TokenNameEnum
 * List of proper names of Cryptocurrency Tokens
 */
export enum TokenNameEnum {
  Bitcoin = 'Bitcoin',
  BitcoinCash = 'Bitcoin Cash',
  Ethereum = 'Ethereum',
  Litecoin = 'Litecoin',
  Stellar = 'Stellar',
  Ripple = 'Ripple',
  Chainlink = 'Chainlink',
  Avalanche = 'Avalanche',
  Algorand = 'Algorand',
  Maker = 'Maker',
  Solana = 'Solana',
  Dogecoin = 'Dogecoin',
}

/**
 * TokenPriceItem
 * Represents the current price of a CryptoCurrency token.  It is populated
 * via a pricing feed from a given Liquidity Provider to reflect the current
 * pricing in order to supplement the user buy/sell trading workflow.
 */
export interface TokenPriceItem {
  /**
   *
   *         Name of the token, ie. Bitcoin, Ethereum
   *
   */
  token_name: TokenNameEnum;
  /**
   *
   *         Trading symbol of the token, ie. BTC, ETH
   *
   */
  base_token: TokenEnum;
  /**
   *
   *         FIAT currency symbol the price is based on ie. USD
   *
   */
  quote_token: CurrencyEnum;
  /**
   * Buy Price
   *
   *         FIAT price to purchase 1 token
   *
   */
  buy_price: string;
  /**
   * Sell Price
   *
   *         FIAT amount to receive per token sold
   *
   *
   */
  sell_price: string;
  /**
   * Quantity
   *
   *         Number of tokens the pricing is based on. Typically this will be 1
   *         but token pricing for example could differ when transacting large lots.
   *         However, for our purposes we will be show pricing based on 1 token.
   *
   */
  quantity: number;
  /**
   *
   *         Indicates if the data was retrieved successfully from the provider and
   *         OK indicates success and data is good.
   *
   */
  status: DataStatusEnum;
  /**
   * Date Updated
   *
   *         UTC time the data pertains to.
   *
   * @format date-time
   */
  date_updated?: string;
}

/** TokenPriceResponse */
export interface TokenPriceResponse {
  /** List of proper names of Cryptocurrency Tokens */
  token_name: TokenNameEnum;
  /** List of supported Cryptocurrency Tokens */
  base_token: TokenEnum;
  /** Quote Token */
  quote_token: string;
  /** Buy Price */
  buy_price: string;
  /** Sell Price */
  sell_price: string;
  /**
   * Daily Percentage Change
   * @default "0.0"
   */
  daily_percentage_change?: string;
  /**
   * Sparkline
   * @default []
   */
  sparkline?: TokenHistoricalPriceData[];
}

/**
 * TradeHistoryOrder
 * The ``TradeHistoryOrder`` class represents a single historical trade
 * from one of our trading partners.
 */
export interface TradeHistoryOrder {
  /** Liquidity Provider Trade Id */
  liquidity_provider_trade_id: string;
  /** List of order side such as buy and sell. */
  order_side: OrderSideEnum;
  /** List of supported Fiat currencies in ISO 4217 */
  currency: CurrencyEnum;
  /** List of supported Cryptocurrency Tokens */
  token: TokenEnum;
  /** Tx Quantity */
  tx_quantity: string;
  /** Tx Gross */
  tx_gross: string;
  /** Tx Fee */
  tx_fee: string;
  /** Tx Net */
  tx_net: string;
  /**
   * Transact Time
   * @format date-time
   */
  transact_time: string;
  /** Status */
  status: string;
}

/**
 * TradingPeriod
 * (Macro) TradingPeriods associated to FinancialInstitutions.
 * Note: This model was initial built with Crypto only in mind. With the
 * introduction of Securities in 7/2024, the model was refactored to support
 * both Crypto and Securities TradingPeriods. We do this using nested
 * Pydantic models for both Crypto and Securities TradingPeriods.
 */
export interface TradingPeriod {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * Start Time
   * @format date-time
   */
  start_time: string;
  /**
   * End Time
   * @format date-time
   */
  end_time: string;
  /** Trading Period Id */
  trading_period_id?: string | null;
  /**
   * Crypto TradingPeriods nested within a TradingPeriod.
   * @default {"status":"open","total_transaction_volume":"0","total_transaction_fees":"0"}
   */
  crypto_trading_period?: CryptoTradingPeriod;
  /**
   * Securities TradingPeriods nested within a TradingPeriod.
   * @default {"sell_side":{"received_dividend_proceeds_ach_push":false,"received_sell_proceeds_ach_push":false,"status":"open","total_transaction_fees":"0","total_transaction_volume":"0"},"buy_side":{"status":"open","total_transaction_fees":"0","total_transaction_volume":"0"},"total_transaction_volume":"0","total_transaction_fees":"0"}
   */
  securities_trading_period?: SecuritiesTradingPeriod;
  /**
   * Excluded Properties
   * @default ["orders","_dyntastic_key_dict","_dyntastic_hash_key"]
   */
  EXCLUDED_PROPERTIES?: any[];
  /**
   * There are only three "macro" states for the parent TradingPeriod:
   * - OPEN
   * - PENDING
   * - FULLY_SETTLED
   * This field is a generalized representation of the underlying state of the nested
   * Crypto + Securities TradingPeriods.
   */
  status: MacroTradingPeriodStatus;
  /**
   * Total Transaction Volume
   * Return the total transaction volume for the TradingPeriod.
   */
  total_transaction_volume: string;
  /**
   * Total Transaction Fees
   * Return the total transaction fees for the TradingPeriod.
   */
  total_transaction_fees: string;
}

/**
 * TradingPeriodConfig
 * Trading period configuration for a financial institution.
 */
export interface TradingPeriodConfig {
  /**
   * Start Time
   * Start time of the trading period in hours (UTC) since midnight.
   * @default 14
   */
  start_time?: number;
  /**
   * Duration Hours
   * Duration of the trading period in hours.
   * @default 24
   */
  duration_hours?: number;
  /**
   * Settle On Weekends
   * Whether to settle on weekends.
   * @default false
   */
  settle_on_weekends?: boolean;
}

/**
 * TradingPeriodOrderMetricsDataAndLPSettlementAmountResponse
 * Response model for the trading_period_reconciliation_manager endpoint.
 */
export interface TradingPeriodOrderMetricsDataAndLPSettlementAmountResponse {
  /** Order Metrics Data */
  order_metrics_data: object;
  /** Lp Net Settlement Value */
  lp_net_settlement_value: string;
}

/**
 * TradingPeriodOrderPaginator
 * Paginates a list of Orders and pagination fields per TradingPeriod.
 * The LastEvaluatedKey includes a trading_period.composite_key.
 */
export interface TradingPeriodOrderPaginator {
  /** Items */
  items: Order[];
  last_evaluated_key?: LastEvaluatedKeyTradingPeriodOrder | null;
  /**
   * Per Page
   * Maximum items to return / returned per page
   */
  per_page: number;
  /**
   * Has More
   *
   *     More items are available to be retrieved using the last_evaluated_key fields
   *
   * @default false
   */
  has_more?: boolean;
}

/**
 * TradingPeriodPaginator
 * Holds list of TradingPeriods and pagination fields.
 */
export interface TradingPeriodPaginator {
  /** Items */
  items: TradingPeriod[];
  last_evaluated_key?: LastEvaluatedKey | null;
  /**
   * Per Page
   * Maximum items to return / returned per page
   */
  per_page: number;
  /**
   * Has More
   *
   *     More items are available to be retrieved using the last_evaluated_key fields
   *
   * @default false
   */
  has_more?: boolean;
}

/**
 * Transaction
 * This is a pydantic.BaseModel class that represents a generic Transaction
 * object (ie: Transactions are NOT stored in the database, but are used to
 * abstract Orders, Dividends, etc. that ARE stored in the database).
 *
 * This model exists to provide a common interface for all transaction types,
 * which helps us:
 * 1. Expose this as a formal type over our API spec to FE consumers.
 * 2. Normalize Order + Dividend data into a model with a common interface
 * before indexing these objects in OpenSearch.
 *
 * Designs: https://cryptofi.atlassian.net/browse/CORE-873
 *
 * NOTE: This model (really, the Transaction OpenSearch index) will eventually
 * overtake the Order OpenSearch Index, as it will be a superset of Order data
 * and Dividend data. As such, we have taken the opportunity to enrich each document
 * in the OpenSearch Index with User data. This will allow us to search by, filter on,
 * and sort by User fields associated to each Transaction. It will also allow us to
 * display the User data associated with each Transaction automatically, without having
 * to query the User Index separately.
 */
export interface Transaction {
  /**
   * P Key
   * The p_key reference to the Order/Dividend.
   */
  p_key: string;
  /**
   * S Key
   * The s_key reference to the Order/Dividend.
   */
  s_key: string;
  /** Order or Dividend. */
  transaction_type: TransactionType;
  /**
   * User Account Id
   * The id of the User associated with the Transaction.
   */
  user_account_id: string;
  /**
   * Fi Id
   * The id of the FI associated with the Transaction.
   */
  fi_id: string;
  /**
   * Fi Name
   * The name of the FI associated with the Transaction.
   */
  fi_name?: string | null;
  /**
   * Trading Period Id
   * The id of the Trading Period associated with the Transaction.
   */
  trading_period_id?: string | null;
  /**
   * Transact Time
   * The datetime of the Transaction.
   * @format date-time
   */
  transact_time: string;
  /**
   * User First Name
   * The first name of the User associated with the Transaction.
   */
  user_first_name?: string | null;
  /**
   * User Last Name
   * The last name of the User associated with the Transaction.
   */
  user_last_name?: string | null;
  /**
   * Order Tx Id
   * If this Transaction is an Order, this represents the tx_id of the Order.
   */
  order_tx_id?: string | null;
  /**
   * Asset
   * The shortname/ticker of the asset associated with the Transaction. eg: BTC, NFLX, APPL
   */
  asset?: string;
  /** The type of the asset (Crypto or Security) of the Transaction. */
  asset_type?: OrderAssetTypeEnum;
  /** The name of the Transaction. eg: Buy, Sell, Dividend */
  transaction_name?: TransactionName;
  /**
   * Frequency
   * The frequency of the Transaction. eg: One Time, Recurring
   * @default "One Time"
   */
  frequency?: string;
  /**
   * Asset Amount
   * The amount of the asset bought/sold of an Order. Or the amount of the asset awareded in the Dividend.
   */
  asset_amount?: number | null;
  /**
   * Usd Amount Gross
   * The gross amount of USD spent/gained for an Order the USD value awarded in the Dividend.
   */
  usd_amount_gross?: number | null;
  /**
   * Usd Amount Net
   * The net USD amount spent/received of an Order after fees. Or the net USD value awarded in the Dividend.
   */
  usd_amount_net?: number | null;
  /**
   * Fee Amount
   * The amount of the tx fee of an Order. Not applicable for Dividends.
   */
  fee_amount?: number | null;
  /**
   * Asset Price
   * The price of the asset at the time of the Transaction.
   */
  asset_price?: number | null;
  /**
   * Status
   * The status of the Transaction.
   */
  status?: DividendStatusEnum | OrderStatusEnum;
}

/**
 * TransactionDirectionEnum
 * This indicates the direction that a transaction is going and is solely used in our admin
 * tooling to move funds between a Member's DDA and the FI General Ledger.
 */
export enum TransactionDirectionEnum {
  FromMemberToGeneralLedger = 'From member to general ledger',
  FromGeneralLedgerToMember = 'From general ledger to member',
}

/**
 * TransactionLimits
 * A model to store the transaction limits for an FI
 */
export interface TransactionLimits {
  /**
   * Min
   * The minimum transaction limit for an FI
   * @default "6.00"
   */
  min?: string;
  /**
   * Max
   * The maximum transaction limit for an FI
   * @default "25000.00"
   */
  max?: string;
}

/** TransactionName */
export enum TransactionName {
  Buy = 'Buy',
  Sell = 'Sell',
  Dividend = 'Dividend',
}

/** TransactionType */
export enum TransactionType {
  Order = 'order',
  Dividend = 'dividend',
}

/**
 * TransactionTypeLimits
 * A model to store the transaction limit types for an FI.
 * This model will store the transaction limits for buying and selling assets.
 */
export interface TransactionTypeLimits {
  /** The transaction limits for buying assets */
  buy?: TransactionLimits;
  /** The transaction limits for selling assets */
  sell?: TransactionLimits;
}

/**
 * UpdateEducationContentSectionRequest
 * Education content section update model.
 */
export interface UpdateEducationContentSectionRequest {
  /**
   * Section Uuid
   * UUID of the section to be updated.
   */
  section_uuid: string;
  /**
   * Title
   * Title of the section.
   */
  title: string;
  /**
   * Image
   * Image URL of the section.
   * @default ""
   */
  image?: string | null;
  /**
   * Fi Id
   * Financial Institution ID.
   */
  fi_id?: string | null;
}

/**
 * UpdateEducationContentTopicRequest
 * Education content topic update model.
 */
export interface UpdateEducationContentTopicRequest {
  /**
   * Topic Uuid
   * UUID of the topic to be updated.
   */
  topic_uuid: string;
  /**
   * Section Uuid
   * UUID of the section the topic belongs to.
   */
  section_uuid: string;
  /**
   * Title
   * Title of the topic.
   */
  title: string;
  /**
   * Image
   * Image URL of the topic. Can be omitted or set to None if no image.
   */
  image?: string | null;
  /**
   * Body
   * Body of the topic.
   */
  body: string;
  /**
   * Fi Id
   * Financial Institution ID.
   */
  fi_id?: string | null;
}

/**
 * UpdateUserCryptoKycFormRequest
 * Handler for crypto-specific KYC status updates.
 *
 * Extends the base KYC form request to handle crypto-specific KYC status updates.
 * Validats the requested status and updates the user's crypto KYC status.
 */
export interface UpdateUserCryptoKycFormRequest {
  /**
   * Form Type
   * @default "crypto"
   */
  form_type?: 'crypto';
  /** The updated kyc status for the user. */
  kyc_status: KYCStatusEnum;
}

/**
 * UpdateUserRequest
 * A generic request object that can be used to update a user.
 * Takes any form that inherits from UpdateUserKycFormRequest.
 */
export interface UpdateUserRequest {
  /**
   * Update User Kyc Form
   * Form for modifying user kyc attribtues
   */
  update_user_kyc_form?: UpdateUserCryptoKycFormRequest | UpdateUserSecuritiesKycFormRequest | null;
}

/**
 * UpdateUserResponse
 * Response object for the update user endpoint
 * Provides the updated user object and whether the user was successfully updated or not
 */
export interface UpdateUserResponse {
  /**
   * Was Updated
   * Whether the user was successfully updated.
   */
  was_updated: boolean;
  /** The updated user object. */
  user: User;
}

/**
 * UpdateUserSecuritiesKycFormRequest
 * Handler for securities-specific KYC status updates.
 *
 * Extends the base KYC form request to handle securities-specific KYC status updates.
 * Validates the current and requested status and updates the user's securities KYC status
 * through their securities settings.
 */
export interface UpdateUserSecuritiesKycFormRequest {
  /**
   * Form Type
   * @default "securities"
   */
  form_type?: 'securities';
  /** The updated kyc status for the user. */
  kyc_status: KYCStatusEnum;
}

/**
 * UploadDocumentTypeEnum
 * Types of legal documents that can be uploaded
 */
export enum UploadDocumentTypeEnum {
  TermsAndConditions = 'terms_and_conditions',
  PrivacyPolicy = 'privacy_policy',
  AuthorizationAgreement = 'authorization_agreement',
  SafeServiceAgreement = 'safe_service_agreement',
  ArbitrationClause = 'arbitration_clause',
}

/** UrlLink */
export interface UrlLink {
  /**
   * Url
   * The URL to the terms and conditions
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string;
  /**
   * Label
   * The UI label for the URL
   */
  label: string;
}

/**
 * User
 * User Record which holds the primary user information such as their name
 * Terms of Use version which they accepted and when it was accepted.
 * User's computed balance records for Cryptocurrency holdings.
 */
export interface User {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account ID
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  user_info?: UserInfo | null;
  /** Tracks user's acceptance of the terms and conditions */
  terms_and_conditions?: UserTermsAndConditions;
  /** A User's balance and aggregate transaction history */
  balance?: Balance;
  /** User's aggregated historical investment value timeseries data */
  aggregate_historical_investment_overtime?: InvestmentHistoricalValue;
  /** Returns the user's securities KYC status as an enum. */
  securities_kyc_status: KYCStatusEnum;
  /**
   * List of risk profiles. While these values are the same as RiskToleranceEnum, the RiskToleranceEnum is
   * used in specific question on the kyc form while the RiskProfileEnum combines several factors to determine
   * which level of risk the user should be allowed to take.
   *
   * This enum will be used to mark both users and assets with a risk level and then be used to determine whether a user's
   * risk level is compatible with the risk level of an asset.
   *
   * NOTE: These are purposefully ints so that the comparison between user and asset risk levels is a simple math calculation.
   * NOTE: This enum purposefully does not have a 0 as it is a falsy value and can introduce bugs were not expected.
   */
  securities_risk_profile: RiskProfileEnum;
  /** Returns the user's crypto KYC status as an enum. */
  crypto_kyc_status: KYCStatusEnum;
}

/**
 * UserAccountMetadata
 * Secondary properties for the user account such as KYC status.
 */
export interface UserAccountMetadata {
  /**
   * Kyc Response
   * Raw KYC Response from securities provider
   */
  kyc_response?: string | null;
  /**
   * Kyc Request Id
   * KYC Request ID from securities provider
   */
  kyc_request_id?: string | null;
  /**
   * Kyc Approval Date
   * Date when the user's KYC was approved
   */
  kyc_approval_date?: string | null;
}

/**
 * UserAssetAllocation
 * This model represents the final asset + allocation percentage in a specific User's
 * RoboPortfolio that may or may not have had reasonable restrictions applied to it.
 * It differs from `RoboPortfolioAssetAllocation` in that it is read-only and does not
 * allow for the `backup_asset` field; if a user has reasonable restrictions, the
 * `backup_asset` is automatically applied.
 */
export interface UserAssetAllocation {
  /**
   * Asset
   * The asset identifier - either a ticker or MMA account
   */
  asset: string;
  /**
   * Allocation Percentage
   * The asset's percentage allocation in the portfolio when balanced
   */
  allocation_percentage: string;
}

/**
 * UserBalanceMarketValue
 * This model validates the request body for the user balance market
 * value endpoint. This model is used to update the user's balance
 * market value.
 */
export interface UserBalanceMarketValue {
  /**
   * Total Value
   * Total value of user's accounts
   * @default "0"
   */
  total_value?: string;
  /**
   * Accounts
   * List of market values for user's accounts
   */
  accounts?: AccountMarketValue[];
}

/**
 * UserComplianceSuitabilitySettingsRequestModel
 * This model validates the request body for the user compliance
 * suitability settings endpoint. Unfortunately, the FE form
 * contains a mix of fields across various Dyntastic models,
 * which dictates the need for this custom model.
 */
export interface UserComplianceSuitabilitySettingsRequestModel {
  /** List of risk tolerances */
  risk_tolerance: RiskToleranceEnum;
  /** Trusted Contactf */
  trusted_contactf?: string | null;
  /** Trusted Contactl */
  trusted_contactl?: string | null;
  /** Trusted Phone */
  trusted_phone?: string | null;
  /** Trusted Email */
  trusted_email?: string | null;
}

/**
 * UserCryptoSelfDirectedAccount
 * Model representing a user's self directed crypto trading account. This model tracks everything related to this account
 */
export interface UserCryptoSelfDirectedAccount {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account ID
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  /**
   * Liquidity Provider Account Id
   * The account id assigned by the liquidity provider
   */
  liquidity_provider_account_id?: string | null;
  /**
   * Balance
   * Balance of the account
   */
  balance?: Record<string, AssetBalance>;
  /** Aggregated historical investment value timeseries data of the account */
  investment_historical_value?: InvestmentHistoricalValue;
  /**
   * User's KYC status for securities trading
   * @default "NOT_STARTED"
   */
  kyc_status?: KYCStatusEnum;
  /** Various secondary properties for the account such as kyc acceptance date */
  metadata?: UserAccountMetadata;
  /**
   * Total Orders
   * Returns the total number of buy and sell orders on the account.
   */
  total_orders: number;
  /**
   * Total Buy Orders
   * Returns the total number of buy orders on the account.
   */
  total_buy_orders: number;
  /**
   * Total Sell Orders
   * Returns the total number of sell orders on the account.
   */
  total_sell_orders: number;
}

/**
 * UserInfo
 * Personal information about the user such as their name.
 */
export interface UserInfo {
  /**
   * First Name
   * User's First Name
   */
  first_name?: string | null;
  /**
   * Middle Name
   * User's Middle Name
   */
  middle_name?: string | null;
  /**
   * Last Name
   * User's Last Name
   */
  last_name?: string | null;
  /**
   * Member Number
   * User's Member Number
   */
  member_number?: string | null;
  /**
   * Phone
   * User's Phone Number
   */
  phone?: string | null;
  /**
   * Email
   * User's Email
   */
  email?: string | null;
}

/**
 * UserPhysicalAddressRequestModel
 * This model validates the request body for the user physical
 * address endpoint.
 *
 * NOTE: These field names are named as such to match RQD's API.
 * These represent the user's street address (add1 and add2), city,
 * state code, and zip code.
 */
export interface UserPhysicalAddressRequestModel {
  /** Add1 */
  add1?: string | null;
  /** Add2 */
  add2?: string | null;
  /** City */
  city?: string | null;
  st_cd?: StateEnum | null;
  /** Zip */
  zip?: string | null;
}

/**
 * UserReasonablyRestrictedAsset
 * Represents a reasonable restriction that a user has placed on their robo account.
 * This restriction is used to exclude certain assets from the user's portfolio and
 * replace them with a back-up asset.
 */
export interface UserReasonablyRestrictedAsset {
  /**
   * Restricted Asset
   * The ticker / identifier of the asset that is being excluded, and thus, we are using the back-up for.
   */
  restricted_asset: string;
}

/**
 * UserReport
 * Represents a report document from either Crypto or Securities.
 *
 * Each report has a unique report_id, report_date, report_type, report_product_type, and report_name.
 *
 * Call presigned_url to get the url for a report.
 */
export interface UserReport {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account Id
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  /**
   * Report Id
   * Unique id for the report, which can be used to fetch the report url
   */
  report_id: string;
  /**
   * Report Date
   * Date the report was generated, e.g. '2024-09-17'
   * @format date
   */
  report_date: string;
  /** Type of report, e.g. 'ConfirmStatement', 'Transactions', etc. */
  report_type: ReportType;
  /** Product type of the report, e.g. 'CRYPTO' or 'SECURITY' */
  report_product_type: ReportProductType;
  /**
   * Report Name
   * Name of the report, e.g. 'Stmt_INFI-001-00000202_20240917_180809_18820.PDF'
   */
  report_name: string;
  /**
   * Report Batch Id
   * Batch ID for the report, if the report was generated in batch processing. For targeting reports generated as part of a batch.
   */
  report_batch_id?: string | null;
}

/** UserResponse */
export interface UserResponse {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account ID
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  user_info?: UserInfo | null;
  /** Tracks user's acceptance of the terms and conditions */
  terms_and_conditions?: UserTermsAndConditions;
  /** A User's balance and aggregate transaction history */
  balance?: Balance;
  /** User's aggregated historical investment value timeseries data */
  aggregate_historical_investment_overtime?: InvestmentHistoricalValue;
  /** The user's self directed crypto account */
  crypto_self_directed_account: UserCryptoSelfDirectedAccount;
  /** The user's self directed security account */
  securities_self_directed_account: UserSecuritiesSelfDirectedAccount;
  /**
   * Robo Accounts
   * List of the user's robo accounts
   */
  robo_accounts?: UserRoboAccountResponse[];
  /** Total value of user's accounts */
  market_value?: UserBalanceMarketValue | null;
  /**
   * Total Buy Orders
   *
   *         Total number of buy orders for this token
   *
   * @default 0
   */
  total_buy_orders?: number;
  /**
   * Total Sell Orders
   *
   *         Total number of sell orders for this token
   *
   * @default 0
   */
  total_sell_orders?: number;
  /**
   * Total Orders
   *
   *         Total number of all orders for this token
   *
   * @default 0
   */
  total_orders?: number;
  /** Returns the user's securities KYC status as an enum. */
  securities_kyc_status: KYCStatusEnum;
  /**
   * List of risk profiles. While these values are the same as RiskToleranceEnum, the RiskToleranceEnum is
   * used in specific question on the kyc form while the RiskProfileEnum combines several factors to determine
   * which level of risk the user should be allowed to take.
   *
   * This enum will be used to mark both users and assets with a risk level and then be used to determine whether a user's
   * risk level is compatible with the risk level of an asset.
   *
   * NOTE: These are purposefully ints so that the comparison between user and asset risk levels is a simple math calculation.
   * NOTE: This enum purposefully does not have a 0 as it is a falsy value and can introduce bugs were not expected.
   */
  securities_risk_profile: RiskProfileEnum;
  /** Returns the user's crypto KYC status as an enum. */
  crypto_kyc_status: KYCStatusEnum;
}

/**
 * UserRoboAccount
 * Represents a user's robo account. This is a 1:M relationship where a user could have multiple robo accounts.
 * This model tracks everything related to this robo account including:
 *     - the kyc information of the account
 *     - its value over time
 *     - the current asset balance of the account
 */
export interface UserRoboAccount {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account ID
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  /**
   * Liquidity Provider Account Id
   * The account id assigned by the liquidity provider
   */
  liquidity_provider_account_id: string;
  /**
   * Balance
   * Balance of the account
   */
  balance?: Record<string, AssetBalance>;
  /** Aggregated historical investment value timeseries data of the account */
  investment_historical_value?: InvestmentHistoricalValue;
  /**
   * User's KYC status for securities trading
   * @default "NOT_STARTED"
   */
  kyc_status?: KYCStatusEnum;
  /**
   * Portfolio Id
   * The RoboPortfolio id the user selected for this account
   */
  portfolio_id?: string | null;
  /**
   * Reasonable Restrictions
   * Reasonable restrictions for the account
   */
  reasonable_restrictions?: UserReasonablyRestrictedAsset[];
  /**
   * User's Risk Profile for securities trading
   * @default -1
   */
  risk_profile?: RiskProfileEnum;
  /**
   * Risk Score
   * The risk score of the portfolio which would further limit the users that can use this portfolio based on their risk score
   */
  risk_score?: number | null;
  /** Various secondary properties for the account such as kyc and risk questionaire answers */
  metadata?: UserRoboAccountMetadata;
  /**
   * Total Orders
   * Returns the total number of buy and sell orders on the account.
   */
  total_orders: number;
  /**
   * Total Buy Orders
   * Returns the total number of buy orders on the account.
   */
  total_buy_orders: number;
  /**
   * Total Sell Orders
   * Returns the total number of sell orders on the account.
   */
  total_sell_orders: number;
  /**
   * Allocations
   * Computed field to get the allocations of the Portfolio associated this Account (user)
   * and dynamically replaces any assets that have been restricted by the user with
   * backup assets.
   */
  allocations: UserAssetAllocation[];
}

/**
 * UserRoboAccountMetadata
 * Stores secondary/sign up information about the user's robo account such as KYC status and risk questionaire answers.
 */
export interface UserRoboAccountMetadata {
  /**
   * Kyc Response
   * Raw KYC Response from securities provider
   */
  kyc_response?: string | null;
  /**
   * Kyc Request Id
   * KYC Request ID from securities provider
   */
  kyc_request_id?: string | null;
  /**
   * Kyc Approval Date
   * Date when the user's KYC was approved
   */
  kyc_approval_date?: string | null;
  /** The user's risk tolerance answers */
  risk_profile_questionnaire_answers?: RTQUserResponse | null;
}

/** UserRoboAccountResponse */
export interface UserRoboAccountResponse {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account ID
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  /**
   * Liquidity Provider Account Id
   * The account id assigned by the liquidity provider
   */
  liquidity_provider_account_id: string;
  /**
   * Balance
   * Balance of the account
   */
  balance?: Record<string, AssetBalance>;
  /** Aggregated historical investment value timeseries data of the account */
  investment_historical_value?: InvestmentHistoricalValue;
  /**
   * User's KYC status for securities trading
   * @default "NOT_STARTED"
   */
  kyc_status?: KYCStatusEnum;
  /**
   * Portfolio Id
   * The RoboPortfolio id the user selected for this account
   */
  portfolio_id?: string | null;
  /**
   * Reasonable Restrictions
   * Reasonable restrictions for the account
   */
  reasonable_restrictions?: UserReasonablyRestrictedAsset[];
  /**
   * User's Risk Profile for securities trading
   * @default -1
   */
  risk_profile?: RiskProfileEnum;
  /**
   * Risk Score
   * The risk score of the portfolio which would further limit the users that can use this portfolio based on their risk score
   */
  risk_score?: number | null;
  /** Various secondary properties for the account such as kyc and risk questionaire answers */
  metadata?: UserRoboAccountMetadata;
  /** Info about the accounts's robo portfolio */
  robo_portfolio_info?: RoboPortfolio | null;
  /**
   * Total Orders
   * Returns the total number of buy and sell orders on the account.
   */
  total_orders: number;
  /**
   * Total Buy Orders
   * Returns the total number of buy orders on the account.
   */
  total_buy_orders: number;
  /**
   * Total Sell Orders
   * Returns the total number of sell orders on the account.
   */
  total_sell_orders: number;
  /**
   * Allocations
   * Computed field to get the allocations of the Portfolio associated this Account (user)
   * and dynamically replaces any assets that have been restricted by the user with
   * backup assets.
   */
  allocations: UserAssetAllocation[];
}

/**
 * UserSearchModel
 * A user in the search index.
 */
export interface UserSearchModel {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account ID
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  user_info?: UserInfo | null;
  /** Tracks user's acceptance of the terms and conditions */
  terms_and_conditions?: UserTermsAndConditions;
  /** Balance and holdings of the user. */
  balance: BalanceSearchModel;
  /**
   * Custodian Account Id
   * User's Custodian Account ID corresponding to their account that holds their cryptocurrency
   */
  custodian_account_id?: string | null;
  /**
   * Securities Liquidity Provider User Id
   * User's Securities Liquidity Provider User ID corresponding to their account that holds their securities
   */
  securities_liquidity_provider_user_id?: string | null;
  /** Returns the user's securities KYC status as an enum. */
  securities_kyc_status: KYCStatusEnum;
  /**
   * List of risk profiles. While these values are the same as RiskToleranceEnum, the RiskToleranceEnum is
   * used in specific question on the kyc form while the RiskProfileEnum combines several factors to determine
   * which level of risk the user should be allowed to take.
   *
   * This enum will be used to mark both users and assets with a risk level and then be used to determine whether a user's
   * risk level is compatible with the risk level of an asset.
   *
   * NOTE: These are purposefully ints so that the comparison between user and asset risk levels is a simple math calculation.
   * NOTE: This enum purposefully does not have a 0 as it is a falsy value and can introduce bugs were not expected.
   */
  securities_risk_profile: RiskProfileEnum;
  /** Returns the user's crypto KYC status as an enum. */
  crypto_kyc_status: KYCStatusEnum;
}

/**
 * UserSecuritiesAccountMetadata
 * Stores secondary/sign up information about the user's robo account such as KYC status and risk profile.
 */
export interface UserSecuritiesAccountMetadata {
  /**
   * Kyc Response
   * Raw KYC Response from securities provider
   */
  kyc_response?: string | null;
  /**
   * Kyc Request Id
   * KYC Request ID from securities provider
   */
  kyc_request_id?: string | null;
  /**
   * Kyc Approval Date
   * Date when the user's KYC was approved
   */
  kyc_approval_date?: string | null;
  /** User's Answers to compliance suitability questions */
  compliance_suitability_fields?: ComplianceSuitabilityFields;
}

/**
 * UserSecuritiesHoldingsSearchModel
 * Securities holdings for a user in the search index.
 */
export interface UserSecuritiesHoldingsSearchModel {
  /**
   * Total Buy Orders
   *
   *         Total number of buy orders for this token
   *
   * @default 0
   */
  total_buy_orders?: number;
  /**
   * Amount Available
   *
   *         Amount of this token user can sell
   *
   * @default 0
   */
  amount_available?: string;
  /**
   * Total Sell Orders
   *
   *         Total number of sell orders for this token
   *
   * @default 0
   */
  total_sell_orders?: number;
  /**
   * Total Orders
   *
   *         Total number of all orders for this token
   *
   * @default 0
   */
  total_orders?: number;
  /**
   * Amount Owned
   *
   *         Amount of this token user owns
   *
   * @default 0
   */
  amount_owned?: string;
  /**
   * Total Cost
   *
   *         total_cost represents the cumlative value of all transactions for a specific token.
   *         In the case of buy orders, it increases by the tx_gross at the time of each purchase.
   *         For sells, it decreases based on the average cost of the owned tokens multiplied by the quantity sold.
   *
   */
  total_cost?: string | null;
  /**
   * Avg Cost
   *
   *         avg_cost denotes the average cost per unit of a specific token, calculated over all buy transactions.
   *         It is determined by dividing the total cost by the total amount of the token owned.
   *         This metric is updated only when new buy orders are executed, reflecting the average price paid per token
   *         unit over time. It remains unchanged during sell orders, providing a consistent reference for calclating
   *         the 'cost basis' (total cost) of the token holdings.
   *
   */
  avg_cost?: string | null;
  /**
   * Asset
   * Asset symbol of the security.
   */
  asset: string;
}

/**
 * UserSecuritiesSelfDirectedAccount
 * Model representing a user's self directed security trading account. This model tracks everything related to this account
 */
export interface UserSecuritiesSelfDirectedAccount {
  /** DynamoDB Partition Key */
  p_key?: string | null;
  /** DynamoDB Sort Key */
  s_key?: string | null;
  /**
   * Financial Institution ID
   * Unique Identifier of the Financial Institution
   */
  fi_id: string;
  /**
   * Model Class
   * Dyntastic Class that defines the model of this record
   */
  cls?: string | null;
  /**
   * System Id
   * Unique ID assigned to record at creation
   */
  system_id?: string;
  /**
   * Date Added
   * GMT based date and time record was added to the system
   * @format date-time
   */
  date_added?: string;
  /**
   * Date Updated
   * GMT based date and time record was last modified or saved
   * @format date-time
   */
  date_updated?: string;
  /**
   * User Account ID
   * Unique Identifier of the User, typically this
   * will be the user's account or CIF ID assigned to them by their Financial Institution
   */
  user_account_id: string;
  /**
   * Liquidity Provider Account Id
   * The account id assigned by the liquidity provider
   */
  liquidity_provider_account_id?: string | null;
  /**
   * Balance
   * Balance of the account
   */
  balance?: Record<string, AssetBalance>;
  /** Aggregated historical investment value timeseries data of the account */
  investment_historical_value?: InvestmentHistoricalValue;
  /**
   * User's KYC status for securities trading
   * @default "NOT_STARTED"
   */
  kyc_status?: KYCStatusEnum;
  /**
   * User's Risk Profile for securities trading
   * @default -1
   */
  risk_profile?: RiskProfileEnum;
  /** Various secondary properties for the account such as kyc and compliance suitability questionaire answers */
  metadata?: UserSecuritiesAccountMetadata;
  /**
   * Total Orders
   * Returns the total number of buy and sell orders on the account.
   */
  total_orders: number;
  /**
   * Total Buy Orders
   * Returns the total number of buy orders on the account.
   */
  total_buy_orders: number;
  /**
   * Total Sell Orders
   * Returns the total number of sell orders on the account.
   */
  total_sell_orders: number;
}

/**
 * UserTermsAndConditionAcceptance
 * Model that stores latest terms and conditions acceptance date and version
 * Also include the history of every version a user has accepted in the past
 */
export interface UserTermsAndConditionAcceptance {
  /**
   * Date Accepted
   * Date the user accepted the terms and conditions
   */
  date_accepted?: string | null;
  /**
   * Version
   * The version of the terms and conditions
   */
  version?: string | null;
  /**
   * History
   * History of terms and conditions acceptance
   */
  history?: UserTermsAndConditionBase[];
}

/** UserTermsAndConditionBase */
export interface UserTermsAndConditionBase {
  /**
   * Date Accepted
   * Date the user accepted the terms and conditions
   */
  date_accepted?: string | null;
  /**
   * Version
   * The version of the terms and conditions
   */
  version?: string | null;
}

/**
 * UserTermsAndConditions
 * Model for tracking a users terms and conditions acceptance and history
 */
export interface UserTermsAndConditions {
  /** Terms and conditions for securities trading */
  securities?: UserTermsAndConditionAcceptance;
  /** Terms and conditions for crypto trading */
  crypto?: UserTermsAndConditionAcceptance;
  /** General Terms and conditions for Investifi */
  investifi?: UserTermsAndConditionAcceptance;
  /** Terms and conditions for Investifi brokerage license */
  investifi_brokerage_license?: UserTermsAndConditionAcceptance;
  /** Terms and conditions for Advisifi brokerage license */
  advisifi_brokerage_license?: UserTermsAndConditionAcceptance;
}

/**
 * UserTokenHoldingsSearchModel
 * Token holdings for a user in the search index.
 */
export interface UserTokenHoldingsSearchModel {
  /**
   * Total Buy Orders
   *
   *         Total number of buy orders for this token
   *
   * @default 0
   */
  total_buy_orders?: number;
  /**
   * Amount Available
   *
   *         Amount of this token user can sell
   *
   * @default 0
   */
  amount_available?: string;
  /**
   * Total Sell Orders
   *
   *         Total number of sell orders for this token
   *
   * @default 0
   */
  total_sell_orders?: number;
  /**
   * Total Orders
   *
   *         Total number of all orders for this token
   *
   * @default 0
   */
  total_orders?: number;
  /**
   * Amount Owned
   *
   *         Amount of this token user owns
   *
   * @default 0
   */
  amount_owned?: string;
  /**
   * Total Cost
   *
   *         total_cost represents the cumlative value of all transactions for a specific token.
   *         In the case of buy orders, it increases by the tx_gross at the time of each purchase.
   *         For sells, it decreases based on the average cost of the owned tokens multiplied by the quantity sold.
   *
   */
  total_cost?: string | null;
  /**
   * Avg Cost
   *
   *         avg_cost denotes the average cost per unit of a specific token, calculated over all buy transactions.
   *         It is determined by dividing the total cost by the total amount of the token owned.
   *         This metric is updated only when new buy orders are executed, reflecting the average price paid per token
   *         unit over time. It remains unchanged during sell orders, providing a consistent reference for calclating
   *         the 'cost basis' (total cost) of the token holdings.
   *
   */
  avg_cost?: string | null;
  /**
   * Asset
   * Asset symbol of the token.
   */
  asset: string;
}

/**
 * UserValues
 * UserValues is a model used to represent all the data used to kyc a user. This model can be used to validate
 * the data coming back from a post request to submit kyc data, and it can also be used to represent kyc data that we
 * already have from the core system.
 *
 * Because we work with different custoidans, the kyc data can be different for each
 * of those custodian. This model is used to represent the data that we need to kyc a user for each of the custodians.
 * In other words, this model is a superset of all the fields that we need to kyc a user for all the custodians that we
 * work with. Thats why all the fields are optional, because we don't need all the fields for all the custodians.
 *
 * Along those lines, if 2 custodians have the same field, but that field is named differently in each custoidan, then
 * this model will default to one of those names, and the translation to the correct name will take place in parse_kyc_event
 * function. For example, sFox has a field called dob, and Etana has a field called date_of_birth. This model will default
 * to date_of_birth, and the translation to dob will take place in parse_kyc_event.
 */
export interface UserValues {
  /**
   * First Name
   * User's First Name
   */
  first_name?: string | null;
  /**
   * Middle Name
   * User's Middle Name
   */
  middle_name?: string | null;
  /**
   * Last Name
   * User's Last Name
   */
  last_name?: string | null;
  /**
   * Date Of Birth
   * User's Date of Birth
   */
  date_of_birth?: string | null;
  /**
   * Ssn
   * User's Social Security Number
   */
  ssn?: string | null;
  /**
   * Address1
   * User's Address - Line 1
   */
  address1?: string | null;
  /**
   * Address2
   * User's Address - Line 2
   */
  address2?: string | null;
  /**
   * City
   * User's City
   */
  city?: string | null;
  /**
   * State
   * User's State
   */
  state?: string | null;
  /**
   * Postal
   * User's Zip Code
   */
  postal?: string | null;
  /**
   * Country
   * User's Country
   */
  country?: string | null;
  /**
   * Phone
   * User's Phone Number
   */
  phone?: string | null;
  /**
   * Email
   * User's Email Address
   */
  email?: string | null;
  /**
   * Employer
   * User's Employer
   * @default ""
   */
  employer?: string | null;
  /**
   * Company Name
   * User's Company Name
   */
  company_name?: string | null;
  /**
   * Previous Employer
   * User's Previous Employer
   */
  previous_employer?: string | null;
  /**
   * The type of banking account the user is signed into.
   * @default "INDIVIDUAL"
   */
  member_type?: BankingUserMemberTypeEnum;
  /**
   * Income Per Year Integer
   * User's Income Per Year
   */
  income_per_year_integer?: number | null;
  /**
   * Net Worth Integer
   * User's Net Worth
   */
  net_worth_integer?: number | null;
  /** User's Investment Objective */
  investment_objective?: InvestmentObjectiveEnum | null;
  /**
   * Years Stocks Integer
   * Number of years of experience trading stocks
   */
  years_stocks_integer?: number | null;
  /** User's Investing Experience Level */
  level_stocks?: InvestmentExperienceLevelEnum | null;
  /**
   * Executive Or Shareholder
   * Are you or a family member an executive or 10% shareholder of a publicly traded company?
   */
  executive_or_shareholder?: string | null;
  /**
   * Work For Exchange Or Brokerage
   * Do you or a family member work for a stock exchange or brokerage?
   */
  work_for_exchange_or_brokerage?: 'Yes' | 'No' | null;
  /**
   * Subject To Backup Withholding
   * Are you subject to backup withholding?
   */
  subject_to_backup_withholding?: 'Yes' | 'No' | null;
  /** What kind of Investor are you? (Risk tolerance) */
  risk_tolerance?: RiskToleranceEnum | null;
  /**
   * Trusted Contactf
   * Trusted Contact/Beneficiary First Name
   * @default ""
   */
  trusted_contactf?: string | null;
  /**
   * Trusted Contactl
   * Trusted Contact/Beneficiary Last Name
   * @default ""
   */
  trusted_contactl?: string | null;
  /**
   * Trusted Phone
   * Trusted Contact/Beneficiary Phone Number
   * @default ""
   */
  trusted_phone?: string | null;
  /**
   * Trusted Email
   * Trusted Contact/Beneficiary Email
   * @default ""
   */
  trusted_email?: string | null;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** States */
export enum LibModelReconciliationBuySideSecuritiesTradingPeriodStateMachineStates {
  Open = 'open',
  NeedsLpAchPull = 'needs-lp-ach-pull',
  FullySettledNoOrders = 'fully-settled-no-orders',
  FullySettled = 'fully-settled',
  NeedsFiRecon = 'needs-fi-recon',
}

/**
 * States
 * All possible states for a TradingPeriod.
 */
export enum LibModelReconciliationCryptoTradingPeriodStateMachineStates {
  Open = 'open',
  ReconciliationInProgress = 'reconciliation-in-progress',
  NeedsFiReconciliation = 'needs-fi-reconciliation',
  NeedsFiSettlement = 'needs-fi-settlement',
  LpSettlementInProgress = 'lp-settlement-in-progress',
  FullySettled = 'fully-settled',
  FullySettledNoOrders = 'fully-settled-no-orders',
}

/** States */
export enum LibModelReconciliationSellSideSecuritiesTradingPeriodStateMachineStates {
  Open = 'open',
  NeedsLpSellSettlement = 'needs-lp-sell-settlement',
  FullySettledNoOrders = 'fully-settled-no-orders',
  FullySettled = 'fully-settled',
  NeedsFiRecon = 'needs-fi-recon',
}
