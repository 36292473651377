import { useMutation } from '@tanstack/react-query';
import { Camelized, decamelizeKeys } from 'humps';

import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { RecurringOrderRequestBody } from '~/types';

const usePostRecurringOrder = () => {
  const { apiClient } = useAxiosContext();

  return useMutation({
    mutationFn: async (body: Camelized<RecurringOrderRequestBody>) => {
      return apiClient?.post(apiRoutes.recurringOrders(), decamelizeKeys(body));
    },
  });
};

export default usePostRecurringOrder;
