'use client';

import { Box, Flex, Text } from '@chakra-ui/react';
import { IconDiamonds, uiColors } from '@cryptofi/core-ui';

import { Investment, UserAssetAllocation } from '~/types';

import PortfolioCompositionChart from '../PortfolioCompositionChart/PortfolioCompositionChart';

const PortfolioRecommendation = ({
  portfolioName = 'No portfolio name',
  portfolioDescription = 'No portfolio description',
  hasPositiveBalance,
  portfolioAllocation,
  assetMetaData,
}: {
  portfolioName?: string;
  portfolioDescription?: string;
  hasPositiveBalance: boolean;
  portfolioAllocation: UserAssetAllocation[];
  assetMetaData: Investment[];
}) => {
  return (
    <Flex flexDir="column" w="full" borderRadius="2xl" border="1px solid" borderColor="brand.500">
      <Flex flexDir="column" gap="4" w="full" p="4" bgColor="brand.50" borderTopRadius="2xl">
        {/* Header */}
        <Flex as="header" justifyContent="space-between" alignItems="center" w="full">
          <Box border="1px solid" borderColor="brand.500" borderRadius="md" bgColor={uiColors.white()} py="1" px="2">
            <Text color="brand.500" fontWeight="bold" fontSize="xs">
              {portfolioName}
            </Text>
          </Box>

          <IconDiamonds __css={{ path: { fill: 'brand.500' } }} boxSize="5" />
        </Flex>

        <Flex flexDir="column" gap="2">
          <Text fontSize="xs">Why did we choose this portfolio for you?</Text>

          <Text color={uiColors.sonicSilver()} fontSize="xs">
            {portfolioDescription}
          </Text>
        </Flex>
      </Flex>

      {/* Body */}
      <Flex flexDir="column" gap="6" w="full" px="4" py="6">
        <Text fontSize="sm" fontWeight="bold">
          What&apos;s in this guided portfolio?
        </Text>

        <PortfolioCompositionChart
          portfolioAllocation={portfolioAllocation}
          assetMetaData={hasPositiveBalance ? assetMetaData : []}
        />
      </Flex>
    </Flex>
  );
};

export default PortfolioRecommendation;
